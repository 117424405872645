import React from 'react';
import { Link } from 'react-router-dom';

const UserPoints = () => {
    const pointsHistory = [
        { description: 'Liked a comment', points: 5, timestamp: '2024-09-24 09:15' },
        { description: 'Commented on a post', points: 10, timestamp: '2024-09-24 10:00' },
        { description: 'Daily login', points: 10, timestamp: '2024-09-25 08:30' },
    ];

    const totalPoints = pointsHistory.reduce((total, item) => total + item.points, 0);

    return (
        <div className="p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">积分信息</h2>
            <div className="mb-4">
                <span className="text-gray-700 font-semibold">当前积分:</span> {totalPoints} 分
            </div>
            <h3 className="text-xl font-semibold mb-3">积分获取记录</h3>
            <ul>
                {pointsHistory.map((record, index) => (
                    <li key={index} className="mb-4">
                        <div className="flex justify-between items-center">
                            <span>{record.description}</span>
                            <span className="text-gray-400 text-sm">{record.timestamp}</span>
                            <span className="text-green-500">+{record.points} 分</span>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="mt-4">
                <Link to="/points-mall" className="text-blue-500 hover:underline">
                    前往积分商城兑换
                </Link>
            </div>
        </div>
    );
};

export default UserPoints;

import React, { useState, useRef, useEffect } from 'react';
import { getRequest } from '../../API/universalRequest';

const AccountInfo = () => {
    const [formData, setFormData] = useState({
        companyName: '企信科技',
        email: 'Qixin@gmail.com.au',
        address: 'Canberra ACT, Australia',
        phone: '+61 484411111',
        password: '',
        confirmPassword: '',
        imgurl: ''
    });

    const [image, setImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
            setFormData({ ...formData, imgurl: imageUrl });
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // 校验密码复杂度（至少8位，包含大小写字母和数字）
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // 校验确认密码和密码是否一致
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage('密码与确认密码不一致');
            return;
        }

        // 校验密码复杂度
        if (!validatePassword(formData.password)) {
            setErrorMessage('密码必须至少8位，包含大小写字母和数字');
            return;
        }

        // 校验通过后，提交表单
        setErrorMessage('');
        // 处理提交逻辑
        console.log('表单提交成功');



    };


    return (
        <div className="bg-white p-6 shadow-md rounded-xl w-full">
            <h2 className="text-xl md:text-2xl font-bold mb-4">账户信息</h2>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row">
                    <div className="w-full space-y-4 mx-auto">
                        {[{ label: '企业名', name: 'companyName', placeholder: '请输入企业名' },
                        { label: '密码', name: 'password', placeholder: '请输入密码', type: 'password' },
                        { label: '确认密码', name: 'confirmPassword', placeholder: '请输入密码', type: 'password' },
                        { label: '企业地址', name: 'address', placeholder: '请输入企业地址' },
                        { label: '电话', name: 'phone', placeholder: '请输入电话号码' }].map((field) => (
                            <div className="flex items-center" key={field.name}>
                                <label className="mr-3 w-2/5 block font-sans text-gray-700 text-sm md:text-base">{field.label} :</label>
                                <input
                                    type={field.type || 'text'}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    className="p-2 border border-gray-300 rounded-2xl text-sm md:text-base w-4/5"
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/* 错误提示 */}
                {errorMessage && (
                    <div className="text-red-500 mt-4 text-center">
                        {errorMessage}
                    </div>
                )}

                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded-xl justify-center">
                        保存更改
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AccountInfo;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatQuestionData } from './questionUtils';

export const fetchQuestions = createAsyncThunk(
  'questions/fetchQuestions',
  async (_, { getState }) => {
    const state = getState();
    const industry = state.questions.selectedIndustry;
    const params = new URLSearchParams();

    if (industry !== "全部") {
      params.append('industry', industry);
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/qa?${params.toString()}`);

    const data = await response.json();
    
    const formattedQuestions = data.map(formatQuestionData);

    return formattedQuestions;
  }
);

export const searchQuestions = createAsyncThunk(
  'questions/searchQuestions',
  async ({ queryKeyword }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (queryKeyword) {
      params.append('queryKeyword', queryKeyword);
    } else {
      throw new Error('Query keyword is required');
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/qa/search?${params.toString()}`);

    if (!response.ok) {
      const errorData = await response.json();
      if (response.status === 404) {
        return rejectWithValue(errorData.message);
      }
      throw new Error('Failed to fetch search results');
    }

    const data = await response.json();
    return data.map(formatQuestionData);
  }
);

const questionSlice = createSlice({
  name: 'questions',
  initialState: {
    questions: [],
    selectedIndustry: '全部',
    loading: false,
    cached: false,
    error: null,
  },
  reducers: {
    setIndustry: (state, action) => {
      state.selectedIndustry = action.payload;
      state.cached = false; // 改变行业时重置缓存状态
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.loading = false;
        state.cached = true; // 数据加载成功后设置缓存为 true
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.loading = false;
      })
      .addCase(searchQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setIndustry } = questionSlice.actions;
export default questionSlice.reducer;
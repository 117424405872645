import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatForUrl } from '../..//Utils/companyUtils';

const getLocalizedUrl = (baseUrl, currentLanguage, endpoint) => {
  return currentLanguage === 'cn' ? `${baseUrl}/cn/${endpoint}` : `${baseUrl}/${endpoint}`;
};

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async ({ region, industry}, {getState}) => {
    const state = getState(); // 获取 Redux store 状态
    const currentLanguage = state.language.language; // 获取当前的语言

    const params = new URLSearchParams();

    if (region !== 'All') {
      params.append('state_en', region);
    }
    if (industry !== '全部') {
      params.append('industrial_cn', industry);
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    // const response = await fetch(`${baseUrl}/company?${params.toString()}`);
    const url = getLocalizedUrl(baseUrl, currentLanguage, `company?${params.toString()}`);
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to fetch advertisements');
    }

    const data = await response.json();

    return data;
  }
);

export const fetchCompanyById = createAsyncThunk(
  'companies/fetchCompanyById',
  async (id, {getState}) => {
    const state = getState();
    const currentLanguage = state.language.language; 

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    // const response = await fetch(`${baseUrl}/company/${id}`);
    const url = getLocalizedUrl(baseUrl, currentLanguage, `company/${id}`);
    const response = await fetch(url);

    const data = await response.json();

    return data;
  }
);

// 根据名称获取公司
export const fetchCompanyByName = createAsyncThunk(
  'companies/fetchCompanyByName',
  async (name, { rejectWithValue, getState}) => {
 
    try {
      const state = getState();
      const currentLanguage = state.language.language;
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
      // const response = await fetch(`${baseUrl}/${currentLanguage}/company`);
      const url = getLocalizedUrl(baseUrl, currentLanguage, 'company');
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch companies');
      }

      const companies = await response.json();

      const company = companies.find((company) => formatForUrl(company.name) === name);

      if (!company) {
        return rejectWithValue('Company not found');
      }

      return company;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// 首页业内好评公司
export const fetchLandingPageCompanies = createAsyncThunk(
  'companies/fetchLandingPageCompanies',
  async (_, {getState}) => {
    console.log('Starting fetchLandingPageCompanies'); // 入口日志

    const state = getState(); // 正确获取 Redux state
    const currentLanguage = state.language.language; 
    
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    // const response = await fetch(`${baseUrl}/company`);
    const url = getLocalizedUrl(baseUrl, currentLanguage, 'company');
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch landing page companies');
    }

    const data = await response.json();
    console.log("home data",data)

    return data;
  }
);

export const fetchAdvertisements = createAsyncThunk(
  'companies/fetchAdvertisements',
  async ({ region, industry }, {getState}) => {
    const state = getState();
    const currentLanguage = state.language.language;

    const params = new URLSearchParams();

    if (region !== 'All') {
      params.append('state_en', region);
    }
    if (industry !== '全部') {
      params.append('industrial_cn', industry);
    }

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    // const response = await fetch(`${baseUrl}/company?${params.toString()}`);
    const url = getLocalizedUrl(baseUrl, currentLanguage, `company?${params.toString()}`);
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to fetch advertisements');
    }

    const data = await response.json();

    return data;
  }
);

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    landingPageCompanies: [],
    company: null,
    advertisements: [],
    loadingCompanies: false,
    loadingLandingPageCompanies: false,
    loadingCompany: false,
    loadingAdvertisements: false,
    errorCompanies: null,
    errorLandingPageCompanies: null,
    errorCompany: null,
    errorAdvertisements: null,
    selectedRegion: 'All',
    selectedIndustry: '全部',
    currentPage: 1,
    totalPages: 1,
    cachedCompanies: false,
    cachedLandingPageCompanies: false,
    cachedCompany: false,
    cachedAdvertisements: false, // 添加缓存标记
  },
  reducers: {
    setRegion: (state, action) => {
      state.selectedRegion = action.payload;
      state.currentPage = 1; // 重置当前页数
      state.cachedCompanies = false
      state.cachedAdvertisements = false; // 改变区域时重置缓存状态
    },
    setIndustry: (state, action) => {
      state.selectedIndustry = action.payload;
      state.currentPage = 1; // 重置当前页数
      state.cachedCompanies = false; 
      state.cachedAdvertisements = false; // 改变行业时重置缓存状态
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
      state.cachedCompanies = false; // 改变页数时重置缓存状态
    },
    clearCachedCompany: (state) => {
      state.company = null;
      state.cachedCompany = false;
      state.errorCompany = null;
      state.loadingCompany = true;
    },  
  },
  extraReducers: (builder) => {
    builder
      // 公司页
      .addCase(fetchCompanies.pending, (state) => {
        state.loadingCompanies = true;
        state.errorCompanies = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.totalPages = Math.ceil(action.payload.length / 12) // 计算总页数
        state.loadingCompanies = false;
        state.cachedCompanies = true; // 数据加载成功后设置缓存为 true
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loadingCompanies = false;
        state.errorCompanies = action.error.message;
      })

      // 广告位
      .addCase(fetchAdvertisements.pending, (state) => {
        state.loadingAdvertisements = true;
        state.errorAdvertisements = null;
      })
      .addCase(fetchAdvertisements.fulfilled, (state, action) => {
        state.advertisements = action.payload;
        state.loadingAdvertisements = false;
        state.cachedAdvertisements = true;
      })
      .addCase(fetchAdvertisements.rejected, (state, action) => {
        state.loadingAdvertisements = false;
        state.errorAdvertisements = action.error.message;
      })

      // 单个公司页面
      .addCase(fetchCompanyById.pending, (state) => {
        state.loadingCompany = true;
        state.errorCompany = null;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.company = action.payload;
        state.loadingCompany = false;
        state.cachedCompany = true;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.loadingCompany = false;
        state.errorCompany = action.error.message;
      })

      .addCase(fetchCompanyByName.pending, (state) => {
        state.loadingCompany = true;
        state.errorCompany = null;
      })
      .addCase(fetchCompanyByName.fulfilled, (state, action) => {
        state.company = action.payload;
        state.loadingCompany = false;
        state.cachedCompany = true;
      })
      .addCase(fetchCompanyByName.rejected, (state, action) => {
        state.loadingCompany = false;
        state.errorCompany = action.payload;
      })      

      // 首页公司
      .addCase(fetchLandingPageCompanies.pending, (state) => {
        state.loadingLandingPageCompanies = true;
        state.errorLandingPageCompanies = null;
      })
      .addCase(fetchLandingPageCompanies.fulfilled, (state, action) => {
        state.landingPageCompanies = action.payload;
        state.loadingLandingPageCompanies = false;
        state.cachedLandingPageCompanies = true;
      })
      .addCase(fetchLandingPageCompanies.rejected, (state, action) => {
        state.loadingLandingPageCompanies = false;
        state.errorLandingPageCompanies = action.error.message;
      });
  },
});

export const { setRegion, setIndustry, setPage, clearCachedCompany } = companySlice.actions;
export default companySlice.reducer;

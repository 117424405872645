import React from 'react';

const BrowsingHistory = () => {
    const history = [
        {
            type: 'activity',
            content: 'You viewed an event: AWS Technology Summit in Sydney.',
            timestamp: '2024-09-20 14:35',
        },
        {
            type: 'news',
            content: 'You read the article: "Construction Industry in 2024: Key Trends."',
            timestamp: '2024-09-22 09:15',
        },
        {
            type: 'company',
            content: 'You visited Qixin Co Pty Ltd company page.',
            timestamp: '2024-09-23 17:00',
        },
        {
            type: 'like',
            content: 'You liked a comment on the post: "Real Estate Market Insights."',
            timestamp: '2024-09-24 10:45',
        },
        {
            type: 'comment',
            content: 'You commented on the article: "How to Improve Construction Safety."',
            timestamp: '2024-09-24 12:00',
        }
    ];

    return (
        <div className="p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">浏览记录</h2>
            <ul>
                {history.map((item, index) => (
                    <li key={index} className="mb-4">
                        <div className="flex justify-between items-center">
                            <span>{item.content}</span>
                            <span className="text-gray-400 text-sm">{item.timestamp}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BrowsingHistory;

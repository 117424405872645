export const formatNewsData = (news) => {
  // 手动解析 'DD/MM/YYYY' 格式的日期
  const [day, month, year] = news.Date.split('/');

  // 使用解析后的年、月、日来构建日期对象
  const date = new Date(`${year}-${month}-${day}`); // 重新排列为 'YYYY-MM-DD' 格式

  // 获取年、月、日并格式化
  const formattedYear = date.getFullYear();
  const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // 注意月份从0开始
  const formattedDay = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

  return {
    id: news.news_id,
    title: news.Title,
    title_en: news.Title_EN,
    content: news.Content,
    content_en: news.Content_EN,
    date: formattedDate, // 使用格式化后的日期
  };
};

import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAlarm } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const EventCard = ({ event }) => {
  const [hovered, setHovered] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const eventsPageData = LanguageData[language].eventsPage;

  const handleCardClick = (e) => {
    // 防止点击链接时触发卡片的点击事件
    if (e.target.tagName !== 'A') {
      // navigate(`/calendar/${event.id}`);
      navigate(getLocalizedPath(`/calendar/${event.id}`, language));
    }
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleCardClick}
      className={`transition-transform transition-opacity duration-300 ease-out cursor-pointer ${hovered ? 'opacity-100 translate-y-0 shadow-lg' : 'opacity-100 translate-y-0 shadow-md'
        }`}
    >
      <div className="flex justify-between bg-white rounded-lg p-4 mb-4">
        <div className="w-1/4 bg-gray-800 text-white p-2 flex flex-col items-center justify-center rounded-l-lg">
          <div className="font-bold flex flex-col lg:flex-row items-center">
            {language === 'en' ? (
              <div className="flex flex-row">
                <span className="text-lg sm:text-xl md:text-2xl mr-1 sm:mr-2 md:mr-3">
                  {new Date(event.start).getDate()}
                </span>
                <span className="text-lg sm:text-xl md:text-2xl">
                  {new Date(event.start).toLocaleString('en-AU', { month: 'short' })}
                </span>
              </div>
            ) : (
              <>
                <span className="text-lg sm:text-xl md:text-2xl">
                  {event.start.slice(5, 7)}月
                </span>
                <span className="text-lg sm:text-xl md:text-2xl">
                  {event.start.slice(-2)}日
                </span>
              </>
            )}
          </div>
        </div>

        <div className="w-2/4 ml-4 flex-grow">
          <h3 className="text-base md:text-lg text-gray-800 font-semibold">{language === "en" ? event.title_en : event.title}</h3>
          <div className="mt-2 flex items-center">
            <span className="text-darkyellow mr-2">
              <IoMdAlarm className="h-5 w-5" />
            </span>
            <span className="text-black px-2 py-1 rounded-full text-xs md:text-sm">
              {event.time}
            </span>
          </div>
        </div>
        <Link
          to={getLocalizedPath(`/calendar/${event.id}`, language)}
          className="w-1/4 mx-2 sm:mx-4 md:mx-6 lg:mx-8 text-center text-gray-600 flex justify-end items-center text-xs md:text-sm hover:text-yellow"
          onClick={(e) => e.stopPropagation()} // 防止触发卡片的点击事件
        >
          {eventsPageData.viewDetails}
        </Link>
      </div>
    </div>
  );
};

const EventLists = ({ events }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const eventsPageData = LanguageData[language].eventsPage;

  return (
    // <div className="m-full mx-4 sm:mx-8 md:mx-12 lg:mx-20 mb-12">
    <div className="m-full mb-12">
      <h1 className="text-3xl font-bold text-start text-yellow mb-6">{eventsPageData.eventsTitle}</h1>
      <div>
        <h2 className="text-2xl font-semibold text-start">2024</h2>
        {events.map((event, index) => (
          <EventCard event={event} key={index} />
        ))}
      </div>
    </div>
  );
};


export default EventLists;
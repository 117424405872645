const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';


export async function getRequest(endPoint, params) {
  console.log('request')

  try {
    const response = await fetch(`${baseUrl}/${endPoint}?${new URLSearchParams(params)}`); // 使用 event.id 进行 API 请求
    if (response.ok) {
      const data = await response.json();
      console.log("Event data by Id:", data);
      return data
    } else {
      console.error("Failed to fetch event data");
    }
  } catch (error) {
    console.error("Error fetching event data:", error);
  }
};

export async function postRequest(endPoint, data) {
  try {
    const response = await fetch(`${baseUrl}${endPoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Inform the server about the data format
      },
      body: JSON.stringify(data)
    }); // 使用 event.id 进行 API 请求  
    if (response.ok) {
      const data = await response.json();
      console.log("Event data by Id:", data);
      return data
    } else {
      console.error("Failed to fetch event data");
    }
  } catch (error) {
    console.error("Error fetching event data:", error);
  }
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // 导入 Link 组件
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import Breadcrums from '../Components/Common/Breadcrums';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageData } from '../Components/Common/LanguageData';
import news_image from '../Assets/news-image.jpg';
import Pagination from '../Components/Common/Pagination';
import newspage_background from '../Assets/newspage-background.jpg';
import PageHeader from '../Components/Common/PageHeader';

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

// 新闻文章组件
const NewsArticle = ({ id, title, date, content }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const newsPageData = LanguageData[language].newsPage;
  const news_date = new Date(date);
  const day = news_date.getDate();
  const month = (language === 'en'? news_date.toLocaleString('en-US', { month: 'short' }): news_date.getMonth()+'月');

  return (
    <Link to={getLocalizedPath(`/news/${id}`, language)}> {/* 将整个新闻项包裹在 Link 中 */}
      <div className="max-w-4xl mx-auto p-0 bg-gray-white shadow-md flex space-x-4 relative hover:bg-gray-200 group">
        <div className="relative w-48 h-40 flex-shrink-0 md:w-60">
          {/* 新闻图片 */}
          <img
            src={news_image}
            alt=""
            className="w-full h-full object-cover p-4"
          />
          {/* 新闻日期 */}
          <div className="absolute top-0 left-0 bg-gray-700 text-white p-2 text-center rounded-sm shadow-lg">
            <p className="text-lg font-bold">{day}</p>
            <p className="uppercase text-sm">{month}</p>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start space-y-2 mt-4">
          {/* 新闻标题*/}
          <h2 className="text-l font-bold text-gray-800 pr-4">
            {title? (title.length > 30 ? title.slice(0, 30) + '...' : title) : ""} {/* 限制标题长度 */}
          </h2>
          {/* 新闻内容 */}
          <p className="text-sm text-gray-600 pr-6 hidden md:block">
            {content? (content.length > 100 ? content.slice(0, 100) + '...' : content) : ""} {/* 限制内容长度 */}
          </p>
          {/* 查看详情按钮 */}
          <button className="text-xs text-gray-700 px-3 py-1 border border-gray-400 group-hover:bg-yellow-500 group-hover:text-white group-hover:border-yellow">{newsPageData.viewDetails}</button>
        </div>
      </div>
    </Link>
  );
};

// 新闻网格组件
const NewsLandingPage = () => {

  const [news, setNews] = useState([]); // 状态管理，保存新闻数据
  const [loading, setLoading] = useState(true); // 状态管理，加载状态
  const [error, setError] = useState(null); // 状态管理，错误信息

  const [currentPage, setCurrentPage] = useState(1);
  const newsPerPage = 12; // 每页12个新闻
  const totalPages = Math.ceil(news.length / newsPerPage);
  const startIndex = (currentPage - 1) * newsPerPage;
  const endIndex = startIndex + newsPerPage;

  const paginatedNews = news.slice(startIndex, endIndex); 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const newsPageData = LanguageData[language].newsPage;

  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${baseUrl}/news`);
        if (!response.ok) {
          throw new Error('网络错误，无法获取新闻。');
        }
        const data = await response.json();
        setNews(data); // 将获取到的新闻数据存入状态
      } catch (err) {
        setError(err.message); // 处理错误
      } finally {
        setLoading(false); // 加载完成
      }
    };

    fetchNews();
  }, [baseUrl]); // 当 baseUrl 变化时重新请求新闻
  // 示例新闻数据

  return (
    <div className="bg-gray-100 pt-4">
      {/* 标题部分 */}
      <div className="text-center mb-12">
        <PageHeader title={ newsPageData.newsTitle } image={ newspage_background } titleStyle = "text-yellow" opacity = "opacity-100"/>
      </div>
      <Breadcrums color="text-gray-700" />
      <hr className="border-t-1 border-gray-300 my-2" />
      <div className="font-sans py-8 px-4">
        <div className="max-w-6xl mx-auto">
          {/* 标题部分
          <div className="text-center mb-12">
            <h1 className="text-4xl text-yellow font-bold text-gray-800 mb-4">{newsPageData.newsTitle}</h1>
            <p className="text-lg text-gray-600">{newsPageData.newsSubtitle}</p>
          </div> */}

          {/* 新闻网格部分 */}
          <div className="flex flex-col mx-auto max-w-4xl divide-y divide-gray-200">
            {paginatedNews.map((item) => (
              <NewsArticle
                id={item.news_id} // 传递 id 属性
                key={item.news_id}
                title={language==="en"? item.Title_EN : item.Title}
                date={item.Date}
                content={language==="en"? item.Content_EN : item.Content}
              />
            ))}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default NewsLandingPage;

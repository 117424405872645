import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-filter backdrop-blur-lg z-50">
      <div className="spinner w-16 h-16 border-4 border-solid rounded-full animate-spin
                  border-l-yellow-500 border-t-gray-200 border-r-yellow-500 border-b-gray-200 border-t-transparent"></div>
    </div>
  );
};

export default LoadingSpinner;

const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export async function fetchEventData(eventId) {
  try {
    const response = await fetch(`${baseUrl}/events/${eventId}`); // 使用 event.id 进行 API 请求
    if (response.ok) {
      const data = await response.json();
      console.log("Event data by Id:", data);
      return data
    } else {
      console.error("Failed to fetch event data");
    }
  } catch (error) {
    console.error("Error fetching event data:", error);
  }
};


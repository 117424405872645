import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.css';
import Footer from './Components/Common/Footer';
import Navbar from './Components/Common/Navbar';
import Landing from './Pages/Landing';
import CompaniesPage from './Pages/CompaniesPage';
import CompanyCalendar from './Pages/CompanyCalendar';
import ContactPage from './Pages/ContactPage';
import SingleCompanyPage from './Pages/SingleCompanyPage';
import EventLandingPage from './Pages/EventLandingPage';
import NewsLandingPage from './Pages/NewsLandingPage';
import NewsDetailPage from './Pages/NewsDetailPage';
import QAPage from './Pages/QAPage';
import CompanyCRM from './Pages/CompanyCRM';
import DetailedQAPage from './Pages/DetailedQAPage';
import PersonCRM from './Pages/PersonCRM';
import PrivateRoute from './Components/Auth/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Helper function to dynamically add /cn prefix based on language
const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

function App() {
  const currentLanguage = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  return (
    <div className='bg-gray-100'>
      <Helmet>
        <title>{currentLanguage==="en"? "Qixin - Link to Aussie Property Services":"企信网 - 您的澳洲房产合作伙伴"}</title>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon-16x16.png" sizes="16x16" type="image/png" />
        <link rel="icon" href="/favicon-32x32.png" sizes="32x32" type="image/png" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className="pt-14"> {/* Adjust this padding based on your navbar height */}
          <Routes>
            <Route path={getLocalizedPath("/", currentLanguage)} element={<Landing />} />
            <Route path={getLocalizedPath("/companies", currentLanguage)} element={<CompaniesPage />} />
            <Route path={getLocalizedPath("/company", currentLanguage)} element={<CompaniesPage />} />
            <Route path={getLocalizedPath("/companies/:industry", currentLanguage)} element={<CompaniesPage />} />
            <Route path={getLocalizedPath("/company/:name", currentLanguage)} element={<SingleCompanyPage />} />
            <Route path={getLocalizedPath("/calendar", currentLanguage)} element={<CompanyCalendar />} />
            <Route path={getLocalizedPath("/calendar/:id", currentLanguage)} element={<EventLandingPage />} />
            <Route path={getLocalizedPath("/news", currentLanguage)} element={<NewsLandingPage />} />
            <Route path={getLocalizedPath("/news/:id", currentLanguage)} element={<NewsDetailPage />} />
            <Route path={getLocalizedPath('/contact', currentLanguage)} element={<ContactPage />} />
            <Route path={getLocalizedPath('/QA', currentLanguage)} element={<QAPage />} />
            <Route path={getLocalizedPath("/QA/:id", currentLanguage)} element={<DetailedQAPage />} />
            <Route path={getLocalizedPath("/companycrm", currentLanguage)} element={<PrivateRoute><CompanyCRM /></PrivateRoute>} />
            <Route path={getLocalizedPath("/personcrm", currentLanguage)} element={<PrivateRoute><PersonCRM /></PrivateRoute>} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

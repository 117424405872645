import React from "react";
import { IoCameraOutline } from "react-icons/io5";
import address_icon from '../../Assets/CompanyPageIcons/address_icon.png';
import industry_icon from '../../Assets/CompanyPageIcons/industry_icon.png';
import phone_icon from '../../Assets/CompanyPageIcons/phone_icon.png';
import email_icon from '../../Assets/CompanyPageIcons/email_icon.png';
import person_icon from '../../Assets/CompanyPageIcons/person_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';

const CompanyCard = ({ company, takeScreenshot, onImageLoad, cameraIconRef, cardRef }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const SingleCompanyPageData = LanguageData[language].SingleCompanyPage; 

  return (
    // <div ref={cardRef} className="relative bg-gray-200 w-80% mx-6 sm:mx-24 md:mx-36 lg:mx-60 h-auto md:aspect-[6/2] mt-6">
    <div ref={cardRef} className="relative bg-beige mx-6 sm:mx-24 md:mx-auto md:w-[720px] lg:w-[1000px] mt-6">

      <div className="flex flex-col md:flex-row justify-center">
        {/* Logo Section */}
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <img
            src={company.image}
            alt={company.title}
            className="w-full h-auto aspect-[3/2] shadow-md"
            onLoad={onImageLoad}
          />
        </div>

        {/* Info Section */}
        <div className="w-full lg:w-1/2 p-2 md:p-4 mt-6 md:mt-0 lg:mt-3 flex flex-col justify-start">
          {/* Company Name */}
          
          <h2 className="text-center text-base md:text-lg font-bold text-gray-800">{company.name}</h2>

          {/* Contact Information */}
          <div className="flex flex-col mt-2 md:mt-0 lg:mt-3 gap-x-6 text-sm md:text-xs lg:text-base">
            {company.description && (
            <p className="flex justify-start items-center mb-2 lg:mb-4 text-gray-600">
              {company.description}
            </p>)}

            {/* <div className="grid grid-cols-2 pl-2">
              {company.location && (
                <p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0zM192 272c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80z" /></svg>
                {company.location}
              </p>)}
              {company.industry && (<p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5L0 80C0 53.5 21.5 32 48 32l149.5 0c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" /></svg>
                {company.industry}
              </p>)}
              {company.contact_person && (<p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" /></svg>
                {company.contact_person}
              </p>)}
              {company.phone && (<p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M347.1 24.6c7.7-18.6 28-28.5 47.4-23.2l88 24C499.9 30.2 512 46 512 64c0 247.4-200.6 448-448 448c-18 0-33.8-12.1-38.6-29.5l-24-88c-5.3-19.4 4.6-39.7 23.2-47.4l96-40c16.3-6.8 35.2-2.1 46.3 11.6L207.3 368c70.4-33.3 127.4-90.3 160.7-160.7L318.7 167c-13.7-11.2-18.4-30-11.6-46.3l40-96z" /></svg>
                {company.phone}
              </p>)}
              {company.wechat && (<p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M385.2 167.6c6.4 0 12.6 .3 18.8 1.1C387.4 90.3 303.3 32 207.7 32 100.5 32 13 104.8 13 197.4c0 53.4 29.3 97.5 77.9 131.6l-19.3 58.6 68-34.1c24.4 4.8 43.8 9.7 68.2 9.7 6.2 0 12.1-.3 18.3-.8-4-12.9-6.2-26.6-6.2-40.8-.1-84.9 72.9-154 165.3-154zm-104.5-52.9c14.5 0 24.2 9.7 24.2 24.4 0 14.5-9.7 24.2-24.2 24.2-14.8 0-29.3-9.7-29.3-24.2 .1-14.7 14.6-24.4 29.3-24.4zm-136.4 48.6c-14.5 0-29.3-9.7-29.3-24.2 0-14.8 14.8-24.4 29.3-24.4 14.8 0 24.4 9.7 24.4 24.4 0 14.6-9.6 24.2-24.4 24.2zM563 319.4c0-77.9-77.9-141.3-165.4-141.3-92.7 0-165.4 63.4-165.4 141.3S305 460.7 397.6 460.7c19.3 0 38.9-5.1 58.6-9.9l53.4 29.3-14.8-48.6C534 402.1 563 363.2 563 319.4zm-219.1-24.5c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.8 0 24.4 9.7 24.4 19.3 0 10-9.7 19.6-24.4 19.6zm107.1 0c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.5 0 24.4 9.7 24.4 19.3 .1 10-9.9 19.6-24.4 19.6z" /></svg>
                {company.wechat}
              </p>)}
            </div>
            {company.email && (<p className="flex justify-start items-center text-gray-700 pl-2">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                {company.email}
            </p>)}
            {company.address && (<p className="flex justify-start items-center text-gray-700 pl-2">
                <svg className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 pr-1 lg:pr-2" style={{ fill: "rgb(107 114 128)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M48 0C21.5 0 0 21.5 0 48L0 464c0 26.5 21.5 48 48 48l96 0 0-80c0-26.5 21.5-48 48-48s48 21.5 48 48l0 80 89.9 0c-6.3-10.2-9.9-22.2-9.9-35.1c0-46.9 25.8-87.8 64-109.2l0-95.9L384 48c0-26.5-21.5-48-48-48L48 0zM64 240c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zm112-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zM80 96l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32zM272 96l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zM576 272a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM352 477.1c0 19.3 15.6 34.9 34.9 34.9l218.2 0c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1l-101.8 0c-51.4 0-93.1 41.7-93.1 93.1z" /></svg>
                {company.address}
            </p>)}
            {company.abn && company.abn.length > 0 && (<p className="flex justify-start items-center text-gray-700 pl-2">
                ABN:  {company.abn}
            </p>)} */}
            <div className="grid grid-cols-2 pl-2">
              {company.location && (
                <p className="flex justify-start items-center text-gray-700">
                <img src={address_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.location}
              </p>)}
              {company.industry && (<p className="flex justify-start items-center text-gray-700">
                <img src={industry_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.industry}
              </p>)}
              {company.contact_person && (<p className="flex justify-start items-center text-gray-700">
                <img src={person_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.contact_person}
              </p>)}
              {company.phone && (<p className="flex justify-start items-center text-gray-700">
                <img src={phone_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.phone}
              </p>)}
              {company.wechat && (<p className="flex justify-start items-center text-gray-700">
                <svg className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" style={{ fill: "black" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M385.2 167.6c6.4 0 12.6 .3 18.8 1.1C387.4 90.3 303.3 32 207.7 32 100.5 32 13 104.8 13 197.4c0 53.4 29.3 97.5 77.9 131.6l-19.3 58.6 68-34.1c24.4 4.8 43.8 9.7 68.2 9.7 6.2 0 12.1-.3 18.3-.8-4-12.9-6.2-26.6-6.2-40.8-.1-84.9 72.9-154 165.3-154zm-104.5-52.9c14.5 0 24.2 9.7 24.2 24.4 0 14.5-9.7 24.2-24.2 24.2-14.8 0-29.3-9.7-29.3-24.2 .1-14.7 14.6-24.4 29.3-24.4zm-136.4 48.6c-14.5 0-29.3-9.7-29.3-24.2 0-14.8 14.8-24.4 29.3-24.4 14.8 0 24.4 9.7 24.4 24.4 0 14.6-9.6 24.2-24.4 24.2zM563 319.4c0-77.9-77.9-141.3-165.4-141.3-92.7 0-165.4 63.4-165.4 141.3S305 460.7 397.6 460.7c19.3 0 38.9-5.1 58.6-9.9l53.4 29.3-14.8-48.6C534 402.1 563 363.2 563 319.4zm-219.1-24.5c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.8 0 24.4 9.7 24.4 19.3 0 10-9.7 19.6-24.4 19.6zm107.1 0c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.5 0 24.4 9.7 24.4 19.3 .1 10-9.9 19.6-24.4 19.6z" /></svg>
                {company.wechat}
              </p>)}
            </div>
            {company.email && (<p className="flex justify-start items-center text-gray-700 pl-2">
                <img src={email_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.email}
            </p>)}
            {company.address && (<p className="flex justify-start items-center text-gray-700 pl-2">
                <img src={address_icon} className="flex-shrink-0 w-3 h-3 lg:w-5 lg:h-5 mr-1 lg:mr-2" />
                {company.address}
            </p>)}
            {company.abn && company.abn.length > 0 && (<p className="flex justify-start items-center text-gray-700 pl-2">
                ABN:  {company.abn}
            </p>)}
          </div>
          
          {/* Screenshot Button in the top right corner */} 
          {/* <div className="absolute bottom-2 right-2 sm:bottom-3 md:right-4" ref={cameraIconRef}> */}
          <div className="flex justify-end ml-auto sm:bottom-3 md:right-4" ref={cameraIconRef}>
            <div className="flex justify-center mt-2 p-1 items-center border border-gray-600 rounded-full">
              <IoCameraOutline
                onClick={takeScreenshot}
                className="h-3 w-3 md:h-5 mr-1 md:w-5 text-gray-500 cursor-pointer hover:background-yellow"
              />
              <div className="text-xs">{SingleCompanyPageData.screenshot}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
import React from 'react'
import { SiMaterialformkdocs } from "react-icons/si";

const Material = () => {
  return (
    <div>
      <SiMaterialformkdocs className="w-16 h-16" /> 
    </div>
  );
};

export default Material;

import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

const QuestionItem = ({ question, onClick }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const categoryMapping = {
    "全部": "All",
    "地产开发": "Property Development",
    "建筑设计": "Architectural Design",
    "房屋建造": "House Construction",
    "建筑材料": "Building Materials",
    "室内装修": "House Renovation",
    "房产经纪": "Real Estate Agency",
    "房屋信贷": "Home Loans",
    "法律咨询": "Legal Advice",
    "会计事务": "Accounting Services"
  };

  return (
    <div
      className="w-full p-4 bg-white cursor-pointer hover:bg-gray-50 flex justify-between items-center"
      onClick={onClick}
    >
      {/* 左侧：问题标题和简介 */}
      <div>
        {/* 标签 */}
        <span className="inline-block bg-yellow text-white px-2 py-1 rounded-full text-xs font-semibold">
          {language==="en"? categoryMapping[question.category]: question.category}
        </span>
        <h2 className="text-black text-lg font-medium">{language==="en"? question.title_en:question.title}</h2>
        <p className="text-gray-500 text-sm">{language==="en"? question.intro_en:question.intro}</p>
      </div>

      {/* 右侧：箭头图标 */}
      <FaArrowRight className="text-gray-400 hover:text-gray-600 transition duration-200" />
    </div>
  );
};

export default QuestionItem;
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRegion, setIndustry, setPage } from './companiesSlice';

const FilterComponent = () => {
  const dispatch = useDispatch();
  const selectedRegion = useSelector(state => state.companies.selectedRegion);
  const selectedIndustry = useSelector(state => state.companies.selectedIndustry);
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const regions = ['All', 'ACT', 'NSW', 'VIC', 'QLD', 'Other'];
  const regionNames = {
  cn: {
    'All': '全部',
    'ACT': '堪培拉 ACT',
    'NSW': '新州 NSW',
    'VIC': '维州 VIC',
    'QLD': '昆州 QLD',
    'Other':'其他 WA SA NT TAS'
  },
  en: {
    'All': 'All',
    'ACT': 'ACT',
    'NSW': 'NSW',
    'VIC': 'VIC',
    'QLD': 'QLD',
    'Other':'WA SA NT TAS'
  }
};

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const industriesData = {
    cn: {
      industries:[
      { name: '全部', value: "全部行业"},
      { name: '地产开发', value: "地产开发"},
      { name: '建筑设计', value: "建筑设计"},
      { name: '房屋建造', value: "房屋建造"},
      { name: '建筑材料', value: "建筑材料"},
      { name: '房屋装修', value: "室内装修"},
      { name: '房产经纪', value: "房产经纪"},
      { name: '房屋信贷', value: "房屋信贷"},
      { name: '法律咨询', value: "法律咨询"},
      { name: '会计事务', value: "会计事务"}
      ]
    },
    en: {
      industries:[
      { name: 'All', value: "全部行业"},
      { name: 'Property Development', value: "地产开发"},
      { name: 'Architectural Design', value: "建筑设计"},
      { name: 'House Construction', value: "房屋建造"},
      { name: 'Building Materials', value: "建筑材料"},
      { name: 'Property Decoration', value: "室内装修"},
      { name: 'Real Estate Agency', value: "房产经纪"},
      { name: 'Home Loans', value: "房屋信贷"},
      { name: 'Legal Advice', value: "法律咨询"},
      { name: 'Accounting Services', value: "会计事务"}
      ]
    }
  };
  const industryMapping = {
    "全部": "ALL INDUSTRIES",
    "地产开发": "PROPERTY DEVELOPMENT",
    "建筑设计": "ARCHITECTURAL DESIGN",
    "房屋建造": "HOUSE CONSTRUCTION",
    "建筑材料": "BUILDING MATERIALS",
    "室内装修": "PROPERTY DECORATION",
    "房产经纪": "REAL ESTATE AGENCY",
    "房屋信贷": "HOME LOANS",
    "法律咨询": "LEGAL ADVICE",
    "会计事务": "ACCOUNTING SERVICES"
  };  

  const handleRegionChange = (region) => {
    dispatch(setRegion(region));
    dispatch(setPage(1));
  };

  const handleIndustryChange = (industry) => {
    dispatch(setIndustry(industry));
    dispatch(setPage(1));
    setIsOpen(false);
  };

  return (
    <div>
      <div className='text-right text-gray-700 sm:px-8 md:px-12 lg:px-16 xl:px-20'>
        <div className='text-xl lg:text-3xl text-black mb-2'>{language==="en"? industryMapping[selectedIndustry]:selectedIndustry}</div>
        <div className='text-lg sm:text-xl md:text-2xl mb-2'>{language==="cn"?industryMapping[selectedIndustry]:""}</div>
        <hr className="mb-8 w-20 h-1 bg-black border-0 ml-auto" />
      </div>
      <div className='flex flex-row justify-between sm:px-8 md:px-12 lg:px-16 xl:px-20 items-center'>
        <div className="flex flex-wrap justify-around gap-1 mb-2">
          {regions.map(region => (
            <button 
              key={region}
              className={`px-2 py-1 border rounded-xs border-gray-300 hover:bg-yellow text-xs lg:text-sm ${selectedRegion === region ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-600'}`}
              onClick={() => handleRegionChange(region)}
            >
              {regionNames[language][region]}
            </button>
          ))}
        </div>

        {/* Dropdown menu for industries */}
        <div className="flex justify-center">
          <div className="relative inline-block text-left">
            <button
              ref={buttonRef}
              onClick={toggleDropdown}
              className="items-center inline-flex justify-center w-[250px] rounded-full border border-yellow shadow-sm px-4 py-1 bg-yellow text-xs lg:text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
            >
              {/* {(language==="en"? industryMapping[selectedIndustry]: selectedIndustry) || '选择行业'} */}
              {
                language === "en"
                  ? industryMapping[selectedIndustry] || 'Select Industry'
                  : selectedIndustry || '选择行业'
              }
              <svg className="-mr-1 ml-2 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="blue" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {/* Dropdown menu */}
            {isOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-1 w-[250px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
              >
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {industriesData[language].industries.map((industry) => (
                    <div key={industry.name}>
                    <button
                      className={`block w-full text-center px-2 py-1 text-sm text-gray-500 hover:bg-gray-200 ${selectedIndustry === industry.value ? 'bg-gray-200' : ''}`}
                      role="menuitem"
                      onClick={() => { handleIndustryChange(industry.value); }}
                    >
                      {industry.name}
                    </button>
                    <hr className="h-0.5 bg-gray-100 border-0 ml-auto" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>  
      </div>
    </div>
  );
};

export default FilterComponent;
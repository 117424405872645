import React, { useState, useEffect } from 'react';
import WenMortgage from '../../Assets/Wen_Mortgage.jpg';
import FeatureBox from './FeatureBox';
import FeatureBar from '../Common/FeatureBar';
import LivecomfyTop from '../../Assets/Livecomfy_Top.png';
import Mortgage from '../../Assets/mortgage';
import Realestate from '../../Assets/realestate';
import Builder from '../../Assets/builder';
import Design from '../../Assets/design';
import Material from '../../Assets/material';
import Agency from '../../Assets/agency';
import Decoration from '../../Assets/decoration';
import Law from '../../Assets/law';
import Accounting from '../../Assets/accounting';
import { useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const FeaturesDisplay = () => {
  const [animate, setAnimate] = useState(false);
  const language = useSelector((state) => state.language.language);
  const industriesData = LanguageData[language].industries;
  const landingData = LanguageData[language].landing;

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className="relative w-full min-h-full">
      {/* 背景渐变 */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-300 to-gray-white pointer-events-none" />
      
      {/* 装饰性背景元素 */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-black to-transparent opacity-50" />
        <div className="absolute top-1/2 left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-gray-100" />
      </div>

      {/* 主要内容 */}
      <div className="relative py-6 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto space-y-4 sm:space-y-8">
          {/* First FeatureBar */}
          <div className="overflow-hidden">
            <div className={`flex flex-col lg:flex-row items-center justify-between space-y-2 lg:space-y-0 lg:space-x-6 transition-all duration-1000 ease-out ${animate ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>
              <div className="w-full lg:w-2/3">
                <FeatureBar imageUrl={WenMortgage} link={getLocalizedPath(`/company/wen-mortgage`, language)}/>
              </div>
              <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-100 w-full lg:w-1/3 text-center lg:text-left">
                {landingData.company1}
              </h2>
            </div>
          </div>

          {/* Second FeatureBar */}
          <div className="overflow-hidden">
            <div className={`flex flex-col lg:flex-row-reverse items-center justify-between space-y-2 lg:space-y-0 lg:space-x-6 transition-all duration-1000 ease-out ${animate ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
              <div className="w-full lg:w-2/3">
                <FeatureBar imageUrl={LivecomfyTop} link={getLocalizedPath(`/company/livecomfy-pty-ltd`, language)} />
              </div>
              <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-100 w-full lg:w-1/3 text-center lg:text-left">
                {landingData.company2}
              </h2>
            </div>
          </div>

          {/* FeatureBoxes */}
          <div className="relative mt-6">
            {/* 过渡装饰 */}
            <div className="absolute -top-16 left-0 w-full h-16 bg-gradient-to-b from-transparent to-gray-100/50 pointer-events-none" />
            
            <div className="relative bg-white/80 backdrop-blur-md rounded-2xl shadow-lg p-6">
              {/* <h2 className="text-xl font-bold text-center mb-6 text-gray-800 relative">
                {landingData.companiesTitle}
              </h2> */}
              <div className="grid grid-cols-3 gap-2 md:gap-6 lg:gap-8 max-w-4xl mx-auto">
                <FeatureBox title={industriesData[1].name} to={industriesData[1].path} Icon={Realestate} />
                <FeatureBox title={industriesData[2].name} to={industriesData[2].path} Icon={Design} />
                <FeatureBox title={industriesData[3].name} to={industriesData[3].path} Icon={Builder} />
                <FeatureBox title={industriesData[4].name} to={industriesData[4].path} Icon={Material} />
                <FeatureBox title={industriesData[5].name} to={industriesData[5].path} Icon={Decoration} />
                <FeatureBox title={industriesData[6].name} to={industriesData[6].path} Icon={Agency} />
                <FeatureBox title={industriesData[7].name} to={industriesData[7].path} Icon={Mortgage} />
                <FeatureBox title={industriesData[8].name} to={industriesData[8].path} Icon={Law} />
                <FeatureBox title={industriesData[9].name} to={industriesData[9].path} Icon={Accounting} />
              </div>
            </div>
          </div>
        </div>

        {/* 底部分隔线 */}
        <div className="relative mt-8 sm:mt-4 lg:mt-12 xl:mt-16">
          <div className="absolute -top-16 left-0 w-full h-16 bg-gradient-to-b from-transparent to-gray-100 pointer-events-none" />
          <hr className="w-5/6 mx-auto h-1 bg-gradient-to-r from-transparent via-yellow-400 to-transparent border-0 rounded-2xl" />
        </div>
      </div>
    </div>
  );
}

export default FeaturesDisplay;
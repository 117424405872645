import React, { useState, useEffect, useRef } from 'react';
import { FaUser, FaEnvelope, FaCalendarAlt, FaEdit, FaShopware } from 'react-icons/fa';

const SidebarNav = ({ onTabChange, showMobileMenu, setShowMobileMenu }) => {
    const [activeTab, setActiveTab] = useState('account');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const menuRef = useRef(null);

    useEffect(() => {
        if (showMobileMenu) {
            const handleClickOutside = (event) => {
                // 如果点击位置不在菜单内，隐藏菜单
                if (menuRef.current && !menuRef.current.contains(event.target)) {
                    setShowMobileMenu(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [showMobileMenu]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        onTabChange(tab);
    };

    return (
        <>
            {!isMobile && (<div className="h-screen w-[160px] bg-white text-black flex flex-col rounded-xl shadow-lg p-4 mb-8">
                <div className="text-center py-4 text-base md:text-lg font-bold text-gray-black">
                    企业管理系统
                </div>
                <hr />
                <ul className="flex-grow mt-4">
                    <li
                        className={`flex items-center justify-center py-3 px-2 mt-2 rounded-xl cursor-pointer hover:bg-yellow ${activeTab === 'account' ? 'bg-yellow text-gray-black' : ''
                            }`}
                        onClick={() => handleTabClick('account')}
                    >
                        <FaUser className="mr-2" /> 账户信息
                    </li>
                    <li
                        className={`flex items-center justify-center py-3 px-2 mt-2 rounded-xl cursor-pointer hover:bg-yellow ${activeTab === 'messages' ? 'bg-yellow text-gray-black' : ''
                            }`}
                        onClick={() => handleTabClick('messages')}
                    >
                        <FaEnvelope className="mr-2" /> 消息中心
                    </li>
                    <li
                        className={`flex items-center justify-center py-3 px-2 mt-2 rounded-xl cursor-pointer hover:bg-yellow ${activeTab === 'eventList' ? 'bg-yellow text-gray-black' : ''
                            }`}
                        onClick={() => handleTabClick('eventList')}
                    >
                        <FaCalendarAlt className="mr-2" /> 活动列表
                    </li>
                    <li
                        className={`flex items-center justify-center py-3 px-2 mt-2 rounded-xl cursor-pointer hover:bg-yellow ${activeTab === 'editHomepage' ? 'bg-yellow text-gray-black' : ''
                            }`}
                        onClick={() => handleTabClick('editHomepage')}
                    >
                        <FaEdit className="mr-2" /> 主页修改
                    </li>
                    <li
                        className={`flex items-center justify-center py-3 px-2 mt-2 rounded-xl cursor-pointer hover:bg-yellow ${activeTab === 'shopmall' ? 'bg-yellow text-gray-black' : ''
                            }`}
                        onClick={() => handleTabClick('shopmall')}
                    >
                        <FaShopware className="mr-2" /> 积分商城
                    </li>
                </ul>
                <div className="p-4 text-gray-black rounded-lg mb-0">
                    © 2024 企信企业管理系统
                </div></div>)}
            {/* 移动设备下拉菜单 */}
            {isMobile && showMobileMenu && (
                <div ref={menuRef} className="w-48 bg-white text-black flex flex-col rounded-xl shadow-lg absolute z-10 right-0">
                    <ul className="flex-grow my-2">
                        <li
                            className={`flex items-center justify-center py-2 px-2 mx-2 mt-2 rounded-xl cursor-pointer rounded-lg hover:bg-yellow ${activeTab === 'account' ? 'bg-yellow text-gray-black' : ''}`}
                            onClick={() => handleTabClick('account')}
                        >
                            <FaUser className="mr-2" /> 账户信息
                        </li>
                        <li
                            className={`flex items-center justify-center py-2 px-2 mx-2 mt-2 rounded-xl cursor-pointer rounded-lg hover:bg-yellow ${activeTab === 'messages' ? 'bg-yellow text-gray-black' : ''}`}
                            onClick={() => handleTabClick('messages')}
                        >
                            <FaEnvelope className="mr-2" /> 消息中心
                        </li>
                        <li
                            className={`flex items-center justify-center py-2 px-2 mx-2 mt-2 rounded-xl cursor-pointer rounded-lg hover:bg-yellow ${activeTab === 'eventList' ? 'bg-yellow text-gray-black' : ''}`}
                            onClick={() => handleTabClick('eventList')}
                        >
                            <FaCalendarAlt className="mr-2" /> 发布活动
                        </li>
                        <li
                            className={`flex items-center justify-center py-2 px-2 mx-2 mt-2 rounded-xl cursor-pointer rounded-lg hover:bg-yellow ${activeTab === 'editHomepage' ? 'bg-yellow text-gray-black' : ''}`}
                            onClick={() => handleTabClick('editHomepage')}
                        >
                            <FaEdit className="mr-2" /> 主页修改
                        </li>
                        <li
                            className={`flex items-center justify-center py-2 px-2 mx-2 mt-2 rounded-xl cursor-pointer rounded-lg hover:bg-yellow ${activeTab === 'shopmall' ? 'bg-yellow text-gray-black' : ''}`}
                            onClick={() => handleTabClick('shopmall')}
                        >
                            <FaShopware className="mr-2" /> 积分商城
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default SidebarNav;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchAdvertisements } from './companiesSlice';
import { formatForUrl } from '../../Utils/companyUtils';

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const AdCard = ({ ad, isLarge, language }) => (
  <Link to={getLocalizedPath(`/company/${formatForUrl(ad.name)}`, language)} target="_blank" rel="noopener noreferrer" className="flex flex-col overflow-hidden shadow-sm h-full group">
    <div className="relative w-full pb-[66.67%]"> {/* 3:2 宽高比 */}
      <img src={ad.image} alt={ad.description} className="absolute inset-0 w-full h-full object-cover" />
    </div>
    <div className="p-2 bg-white transition duration-300 ease-in-out group-hover:bg-yellow flex-grow">
      <h3 className={`font-bold text-black ${isLarge ? 'text-base md:text-lg' : 'text-xs md:text-sm'} truncate`}>{ad.name}</h3>
      <p className="text-gray-600 text-xs truncate">{ad.description}</p>
    </div>
  </Link>
);

const AdvertisementGrid = () => {
  const dispatch = useDispatch();
  const { advertisements = [], cachedAdvertisements } = useSelector((state) => state.companies);
  const [companyGroups, setCompanyGroups] = useState([]);
  const { industry } = useParams();
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const categories = [
    'realestate', 'design', 'builder', 'material', 'decoration',
    'agency', 'mortgage', 'law', 'accounting', 'all'
  ];

  useEffect(() => {
    if (!cachedAdvertisements) {
      dispatch(fetchAdvertisements({ region: 'All', industry: '全部' }));
    }
  }, [cachedAdvertisements, dispatch]);

  useEffect(() => {
    const groups = categories.map((category) => {
      const bigAdCompanies = advertisements.filter(company =>
        company.company_level && company.company_level.includes(`BigAd_${category}`)
      );

      const smallAdCompanies = advertisements.filter(company =>
        company.company_level && company.company_level.includes(`SmallAd_${category}`)
      );

      return {
        category,
        bigAd: bigAdCompanies[0] || { id: 'default', name: 'Default BigAd' },
        smallAds: smallAdCompanies.slice(0, 4),
      };
    });

    setCompanyGroups(groups);
  }, [advertisements]);

  const matchedGroup = companyGroups.find(group => group.category === industry) ||
    companyGroups.find(group => group.category === 'all');

  if (!matchedGroup) return null;

  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="flex flex-col md:flex-row ipad-pro:flex-col ipad-air:flex-col gap-4">
        {/* Big Ad */}
        <div className="w-full md:w-1/2 ipad-pro:w-full ipad-air:w-full">
          <AdCard ad={matchedGroup.bigAd} isLarge={true} language={language} />
        </div>
        {/* Small Ads */}
        <div className="w-full md:w-1/2 ipad-pro:w-full ipad-air:w-full grid grid-cols-2 gap-4">
          {matchedGroup.smallAds.map((ad) => (
            <AdCard key={ad.id} ad={ad} isLarge={false} language={language} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvertisementGrid;
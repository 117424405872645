import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments, postComment, deleteComment, updateComment } from './commentsSlice';
import LoginComponent from '../Auth/LoginComponent';
import { LanguageData } from '../Common/LanguageData';

const CommentSection = ({ companyId }) => {
    const dispatch = useDispatch();
    const { comments, loading, error } = useSelector((state) => state.comments);
    const { email } = useSelector((state) => state.auth); // 获取当前登录用户的email
    const [newComment, setNewComment] = useState('');
    const [replyCommentId, setReplyCommentId] = useState(null); // 追踪正在回复的评论ID
    const [replyText, setReplyText] = useState(''); // 追踪回复文本
    const [editCommentId, setEditCommentId] = useState(null); // 追踪正在编辑的评论ID
    const [editCommentText, setEditCommentText] = useState(''); // 编辑时的评论文本
    const [showLogin, setShowLogin] = useState(false); // 控制登录弹窗显示

    const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
    const SingleCompanyPageData = LanguageData[language].SingleCompanyPage; // 获取当前语言的 industries 内容

    useEffect(() => {
        dispatch(fetchComments(companyId));
    }, [dispatch, companyId]);

    // 提交新评论
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (newComment.trim()) {
            if (!email) {
                setShowLogin(true); // 未登录时显示登录弹窗
                return;
            }
            await dispatch(postComment({ companyId, commentText: newComment, userId: email }));
            setNewComment(''); // 提交后清空输入框
            dispatch(fetchComments(companyId)); // 提交评论后重新获取评论
        }
    };

    // 提交回复
    const handleReplySubmit = async (e) => {
        e.preventDefault();
        if (replyText.trim()) {
            if (!email) {
                setShowLogin(true); // 未登录时显示登录弹窗
                return;
            }
            await dispatch(postComment({ companyId, commentText: replyText, userId: email, parentCommentId: replyCommentId }));
            setReplyCommentId(null); // 清空回复状态
            setReplyText(''); // 清空回复文本
            dispatch(fetchComments(companyId)); // 提交回复后重新获取评论
        }
    };

    // 删除评论
    const handleDeleteComment = (commentId) => {
        dispatch(deleteComment(commentId));
    };

    // 开始编辑评论
    const handleEditComment = (commentId, commentText) => {
        setEditCommentId(commentId); // 设置当前编辑的评论ID
        setEditCommentText(commentText); // 填充编辑的文本
    };

    // 提交修改的评论
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (editCommentText.trim()) {
            await dispatch(updateComment({ commentId: editCommentId, commentText: editCommentText }));
            setEditCommentId(null); // 清空编辑状态
            setEditCommentText(''); // 清空编辑文本
        }
    };

    // 取消编辑
    const handleCancelEdit = () => {
        setEditCommentId(null);
        setEditCommentText('');
    };

    // 时间戳转日期
    const convertFirestoreTimestamp = (timestamp) => {
        if (timestamp && timestamp._seconds) {
            const date = new Date(timestamp._seconds * 1000);
            return date.toLocaleString();
        } else {
            return '时间格式错误';
        }
    };

    // 获取评论的回复
    const getReplies = (commentId) => {
        return comments.filter(comment => comment.parent_comment_id === commentId && comment.comment_id !== commentId);
    };

    // 递归渲染评论
    const renderComments = (comment, isTopLevel, isLastTopLevel) => {
        return (
            <li
                key={comment.comment_id}
                className={`mb-4 ${isTopLevel && !isLastTopLevel ? 'pb-2 border-b border-gray-300' : ''} ${comment.parent_comment_id ? 'ml-8' : ''}`}
            >
                {editCommentId === comment.comment_id ? (
                    // 如果正在编辑这条评论，展示编辑表单
                    <form onSubmit={handleEditSubmit}>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded-md mb-2"
                            rows="3"
                            value={editCommentText}
                            onChange={(e) => setEditCommentText(e.target.value)}
                        />
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-yellow-500 text-black px-4 py-2 rounded-lg"
                            >
                                提交修改
                            </button>
                            <button
                                type="button"
                                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg"
                                onClick={handleCancelEdit}
                            >
                                取消
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="flex justify-between items-center">
                        <div className="text-left">
                            <p className={`${comment.parent_comment_id ? 'ml-4' : ''}`}>{comment.comment_text}</p>
                            <span className="text-sm text-gray-600">
                                由 {comment.user_id || '匿名用户'} 于{' '}
                                {convertFirestoreTimestamp(comment.created_at)} 发布
                            </span>
                        </div>
                        <div className="flex justify-end items-center space-x-4">
                            {comment.user_id === email && (
                                <>
                                    <button
                                        className="text-blue-500"
                                        onClick={() => handleEditComment(comment.comment_id, comment.comment_text)}
                                    >
                                        编辑
                                    </button>
                                    <button
                                        className="text-red-500"
                                        onClick={() => handleDeleteComment(comment.comment_id)}
                                    >
                                        删除
                                    </button>
                                </>
                            )}
                            <button
                                className="text-yellow-500"
                                onClick={() => {
                                    // 如果当前回复的是这条评论，再次点击时收起回复表单
                                    if (replyCommentId === comment.comment_id) {
                                        setReplyCommentId(null);
                                    } else {
                                        setReplyCommentId(comment.comment_id);
                                    }
                                }}
                            >
                                {replyCommentId === comment.comment_id ? '取消回复' : '回复'}
                            </button>
                        </div>
                    </div>
                )}

                {/* 如果正在回复这条评论，显示回复表单 */}
                {replyCommentId === comment.comment_id && (
                    <form onSubmit={handleReplySubmit} className="mt-4">
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded-md mb-2"
                            rows="3"
                            placeholder="输入您的回复..."
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            required
                        />
                        <button
                            type="submit"
                            className="bg-yellow-500 text-black px-4 py-2 rounded-lg"
                        >
                            提交回复
                        </button>
                    </form>
                )}

                {/* 递归渲染回复 */}
                <ul>
                    {getReplies(comment.comment_id).map(reply => renderComments(reply, false))}
                </ul>
            </li>
        );
    };

    if (loading) return <p className="text-gray-600">加载评论中...</p>;
    if (error) return <p className="text-red-500">错误: {error}</p>;

    // 过滤顶层评论
    const topLevelComments = comments.filter(comment => !comment.parent_comment_id);

    return (
        // <div className="bg-white p-8 rounded-lg shadow-md mt-6 mx-12 border border-gray-300">
        <div className="bg-white p-8 shadow-md mt-12 mx-6 sm:mx-24 md:mx-auto md:w-[720px] lg:w-[1000px] border border-gray-300">
            <p className="text-lg font-bold mb-4 text-black">{SingleCompanyPageData.comment}</p>

            <ul className="mb-4">
                {topLevelComments.map((comment, index) =>
                    renderComments(comment, true, index === topLevelComments.length - 1)
                )}
            </ul>

            {/* 添加新评论的表单 */}
            <form onSubmit={handleCommentSubmit}>
                <textarea
                    className="w-full p-2 border border-gray-300 rounded-md mb-2 bg-white text-black placeholder-gray-500"
                    rows="3"
                    placeholder={SingleCompanyPageData.commentPlaceholder}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    required
                />
                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="bg-yellow-500 text-black px-4 py-2 rounded-lg border border-yellow-500 hover:bg-yellow-400 transition duration-200"
                    >
                        {SingleCompanyPageData.submitComment}
                    </button>
                </div>
            </form>

            {/* 未登录时显示登录弹窗 */}
            {showLogin && <LoginComponent closeLogin={() => setShowLogin(false)} />}
        </div>
    );
};

export default CommentSection;

import React from 'react';
import GuideIconsItem from './GuideIconsItem';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';

const GuideSteps = ({ industry }) => {
  // Step definitions mapped by industry
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言
  const stepsMapData = LanguageData[language].stepsMap;

  // Select the appropriate steps based on the industry prop
  const steps = stepsMapData[industry] || [];

  return (
    <GuideIconsItem steps={steps} />
  );
};

export default GuideSteps;

import React from 'react';
import { FaPencilAlt } from "react-icons/fa";

const CRMEventList = ( {activities, onPostEventClick }) => {

  return (
    <div className="bg-white p-6 bg-white shadow-md rounded-xl">
      <h2 className="text-xl md:text-2xl font-semibold mb-4">活动列表</h2>
      <div className="overflow-x-auto">
        <table className="w-full text-left table-auto border-collapse">
          <thead>
            <tr className="bg-gray-200 text-center">
              <th className="py-2 rounded-tl-md">活动名称</th>
              <th className="py-2">创建时间</th>
              <th className="py-2">状态</th>
              <th className="py-2 rounded-tr-md">操作</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(activities) && activities.length > 0 ? (
            activities.map((activity) => (
              <tr key={activity.id} className="border-b hover:bg-gray-100 text-center">
                <td className="py-2 text-xs sm:text-sm md:text-base rounded-l-md">
                  {activity.name}
                </td>
                <td className="py-2 text-xs sm:text-sm md:text-base">{activity.createdAt}</td>
                <td className="py-2 rounded-r-md">
                <span
                  className={`py-1 rounded-full text-xs sm:text-sm md:text-base font-semibold ${
                    activity.status === '审核中'
                      ? 'text-darkyellow'
                      : activity.status === '未通过'
                      ? 'text-red'
                      : activity.status === '已发布'
                      ? 'text-green'
                      : 'text-black'
                  }`}
                >
                    {activity.status}
                  </span>
                </td>
                <td className="px-4 py-2 rounded-r-md">
                  {(activity.status === '已发布' || activity.status === '未通过') && (
                    <button
                      onClick={() => onPostEventClick('edit')}
                      className="text-blue-500 hover:text-blue-700 flex items-center mx-auto"
                      title="编辑活动"
                    >
                      <FaPencilAlt size={16} />
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center py-4">没有活动记录</td>
            </tr>
          )}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button onClick={() => onPostEventClick('post')} className="bg-yellow text-gray-800 text-sm sm:text-base rounded-xl px-4 py-2 hover:bg-lightyellow">
            发布新活动
          </button>
        </div>
      </div>
    </div>
  );
}

export default CRMEventList;
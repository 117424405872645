import React from 'react';

const FormattedText = ({ text }) => {
  const formatText = (text) => {
    // Check if text is empty, null, or undefined; if so, return an empty array
    if (!text || typeof text !== 'string') {
      return [];
    }

    // 首先按原始换行符分割文本
    const rawParagraphs = text.split(/\n+/).filter(p => p.trim().length > 0);
    
    const formattedParagraphs = rawParagraphs.map(paragraph => {
      // 去除段落首尾的空白字符
      paragraph = paragraph.trim();
      
      // 如果段落长度超过300字符，进行分割
      if (paragraph.length > 300) {
        const sentences = paragraph.match(/[^.!?]+[.!?]*[\s]?/g) || [paragraph];
        let formattedParagraph = '';
        let currentLine = '';
  
        sentences.forEach(sentence => {
          if (currentLine.length + sentence.length > 300) {
            formattedParagraph += (currentLine + '\n').trim();
            currentLine = '';
          }
          currentLine += sentence + ' ';
        });
  
        formattedParagraph += currentLine.trim();
        return formattedParagraph;
      }
      
      return paragraph;
    });

    return formattedParagraphs;
  };

  const paragraphs = formatText(text);

  return (
    <div className="formatted-text">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-base leading-relaxed whitespace-pre-line">
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default FormattedText;
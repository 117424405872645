import { LuBookKey } from "react-icons/lu";
import React from 'react'

const Accounting = () => {
    return (
      <div>
        <LuBookKey className="w-16 h-16" /> 
      </div>
    );
  };
  
  export default Accounting;
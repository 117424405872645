import React from "react";
import { GrPaint } from "react-icons/gr";

const Decoration = () => {
  return (
    <div>
      <GrPaint className="w-16 h-16" />
    </div>
  );
};

export default Decoration;
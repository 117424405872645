import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux'; // 用于获取 Redux 中的状态
import { getRequest, postRequest } from '../../API/universalRequest';

const AccountInfo = () => {
    // 从 Redux 中获取用户邮箱
    const userEmail = useSelector((state) => state.auth.email);

    const [formData, setFormData] = useState({
        username: 'user123',
        email: userEmail || 'user@example.com', // 从 Redux 获取邮箱信息，作为默认值
        phone: '+61 484411111',
        password: '',
        confirmPassword: '',
        imgurl: ''
    });

    const [image, setImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef(null);

    // 处理头像上传
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
            setFormData({ ...formData, imgurl: imageUrl });
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    // 处理图片点击（打开文件选择器）
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    // 处理表单字段变化
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // 校验密码复杂度（至少8位，包含大小写字母和数字）
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // 校验确认密码和密码是否一致
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage('密码与确认密码不一致');
            return;
        }

        // 校验密码复杂度
        if (!validatePassword(formData.password)) {
            setErrorMessage('密码必须至少8位，包含大小写字母和数字');
            return;
        }

        // 校验通过后，提交表单
        setErrorMessage('');
        // 处理提交逻辑
        console.log('表单提交成功');

        postRequest('/user', formData).then(res => {
            console.log(res)

        })
    };

    return (
        <div className="bg-white p-6 shadow-md rounded-lg w-full">
            <h2 className="text-2xl font-bold mb-4">个人账户信息</h2>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row ml-20">
                    <div className="w-full md:w-3/5 space-y-4">
                        {/* 用户名、邮箱、电话、密码和确认密码 */}
                        {[{ label: '用户名', name: 'username', placeholder: '请输入用户名' },
                        { label: '邮箱', name: 'email', placeholder: '请输入邮箱', type: 'email' },
                        { label: '密码', name: 'password', placeholder: '请输入密码', type: 'password' },
                        { label: '确认密码', name: 'confirmPassword', placeholder: '请确认密码', type: 'password' }].map((field) => (
                            <div className="flex items-center" key={field.name}>
                                <label className="mr-3 w-2/5 block font-sans text-gray-700 text-sm">{field.label} :</label>
                                <input
                                    type={field.type || 'text'}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    className="p-2 border border-gray-300 rounded-2xl text-md w-4/5"
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                    {/* 用户头像上传 */}
                    <div className="w-full md:w-2/5 flex flex-col items-center mt-4 md:mt-0">
                        <div
                            className="w-24 h-24 rounded-full bg-gray-200 cursor-pointer overflow-hidden"
                            onClick={handleImageClick}
                        >
                            {image ? <img src={image} alt="用户头像" className="w-full h-full object-cover" /> : (
                                <div className="w-full h-full flex items-center justify-center text-gray-500">
                                    点击上传头像
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>

                {/* 错误提示 */}
                {errorMessage && (
                    <div className="text-red-500 mt-4 text-center">
                        {errorMessage}
                    </div>
                )}

                {/* 保存按钮 */}
                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded">
                        保存更改
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AccountInfo;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// 获取某公司的所有评论
export const fetchComments = createAsyncThunk(
    'comments/fetchComments',
    async (companyId) => {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await axios.get(`${baseUrl}/comments/${companyId}`);
        return response.data;
    }
);

// 创建评论
export const postComment = createAsyncThunk(
    'comments/postComment',
    async ({ companyId, commentText, userId, parentCommentId }) => {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await axios.post(`${baseUrl}/comments/${companyId}`, {
            commentText,
            userId,
            parentCommentId,
        });
        return response.data;
    }
);

// 更新评论
export const updateComment = createAsyncThunk(
    'comments/updateComment',
    async ({ commentId, commentText }) => {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await axios.put(`${baseUrl}/comments/${commentId}`, {
            commentText,
        });
        return response.data;
    }
);

// 删除评论
export const deleteComment = createAsyncThunk(
    'comments/deleteComment',
    async (commentId) => {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await axios.delete(`${baseUrl}/comments/${commentId}`);
        return response.data;
    }
);

const commentsSlice = createSlice({
    name: 'comments',
    initialState: {
        comments: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // 获取评论
            .addCase(fetchComments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.loading = false;
                state.comments = action.payload; // 更新状态为获取到的评论
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // 处理错误
            })

            // 发布评论
            .addCase(postComment.fulfilled, (state, action) => {
                state.comments.push(action.payload); // 新评论添加到列表
            })

            // 更新评论
            .addCase(updateComment.fulfilled, (state, action) => {
                const index = state.comments.findIndex(
                    (comment) => comment.comment_id === action.payload.comment_id
                );
                if (index !== -1) {
                    state.comments[index] = action.payload; // 更新对应的评论内容
                }
            })

            // 删除评论
            .addCase(deleteComment.fulfilled, (state, action) => {
                state.comments = state.comments.filter(
                    (comment) => comment.comment_id !== action.meta.arg // 删除指定 ID 的评论
                );
            });
    },
});

export default commentsSlice.reducer;

import React, { useState, useEffect, useRef } from 'react';
import { FaUser, FaEnvelope, FaHistory, FaGift, FaSignInAlt } from 'react-icons/fa';

const SidebarNav = ({ onTabChange, showMobileMenu, setShowMobileMenu }) => {
    const [activeTab, setActiveTab] = useState('account');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const menuRef = useRef(null);

    useEffect(() => {
        if (showMobileMenu) {
            const handleClickOutside = (event) => {
                // 如果点击位置不在菜单内，隐藏菜单
                if (menuRef.current && !menuRef.current.contains(event.target)) {
                    setShowMobileMenu(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [showMobileMenu]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        onTabChange(tab);
    };

    return (
        <>
            {!isMobile && (
                <div className="h-screen w-1/4 bg-white text-black flex flex-col rounded-lg shadow-lg">
                    <div className="text-center py-4 text-lg font-bold bg-[#f7cc11] text-gray-black rounded-lg">
                        用户管理系统
                    </div>
                    <ul className="flex-grow mt-4">
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer hover:bg-[#f7cc11] ${activeTab === 'account' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('account')}
                        >
                            <FaUser className="mr-3" /> 账户信息
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer hover:bg-[#f7cc11] ${activeTab === 'messages' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('messages')}
                        >
                            <FaEnvelope className="mr-3" /> 消息
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer hover:bg-[#f7cc11] ${activeTab === 'history' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('history')}
                        >
                            <FaHistory className="mr-3" /> 浏览记录
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer hover:bg-[#f7cc11] ${activeTab === 'points' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('points')}
                        >
                            <FaGift className="mr-3" /> 积分商城
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer hover:bg-[#f7cc11] ${activeTab === 'enterpriseRedirect' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('enterpriseRedirect')}
                        >
                            <FaSignInAlt className="mr-3" /> 企业用户入驻
                        </li>
                    </ul>
                    <div className="p-4 bg-[#f7cc11] text-gray-black rounded-lg mb-0">
                        © 2024 用户管理系统
                    </div>
                </div>
            )}
            {/* 移动设备下拉菜单 */}
            {isMobile && showMobileMenu && (
                <div ref={menuRef} className="w-64 bg-white text-black flex flex-col rounded-lg shadow-lg absolute z-10 right-0">
                    <ul className="flex-grow mt-4">
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer rounded-lg hover:bg-[#f7cc11] ${activeTab === 'account' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('account')}
                        >
                            <FaUser className="mr-3" /> 账户信息
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer rounded-lg hover:bg-[#f7cc11] ${activeTab === 'messages' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('messages')}
                        >
                            <FaEnvelope className="mr-3" /> 消息
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer rounded-lg hover:bg-[#f7cc11] ${activeTab === 'history' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('history')}
                        >
                            <FaHistory className="mr-3" /> 浏览记录
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer rounded-lg hover:bg-[#f7cc11] ${activeTab === 'points' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('points')}
                        >
                            <FaGift className="mr-3" /> 积分商城
                        </li>
                        <li
                            className={`flex items-center py-3 px-4 cursor-pointer rounded-lg hover:bg-[#f7cc11] ${activeTab === 'enterpriseRedirect' ? 'bg-[#f7cc11] text-gray-black' : ''}`}
                            onClick={() => handleTabClick('enterpriseRedirect')}
                        >
                            <FaSignInAlt className="mr-3" /> 企业用户入驻
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default SidebarNav;

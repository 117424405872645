import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyByName, clearCachedCompany } from '../Components/Companies/companiesSlice';
import { fetchQuestions, searchQuestions, setIndustry } from '../Components/QA/questionSlice';
import { formatForUrl } from '../Utils/companyUtils';
import FormattedText from '../Components/Common/FormattedText';
import Item from '../Components/Companies/Item';
import LoadingSpinner from '../Components/Common/LoadingSpinner';

const Sponsors = {
  cn: {
    '法律咨询': '陈宇律师楼',
    '房屋信贷': 'Wen Mortgage',
    '会计事务': '如是会计事务所',
    131: 'IA建筑设计',
  },
  en: {
    '法律咨询': 'Juris Cor Legal',
    '房屋信贷': 'Wen Mortgage',
    '会计事务': 'Solution In',
    131: 'IA Architects',
  }
};

const categoryMapping = {
  "全部": "All",
  "地产开发": "Property Development",
  "建筑设计": "Architectural Design",
  "房屋建造": "House Construction",
  "建筑材料": "Building Materials",
  "室内装修": "House Renovation",
  "房产经纪": "Real Estate Agency",
  "房屋信贷": "Home Loans",
  "法律咨询": "Legal Advice",
  "会计事务": "Accounting Services"
};

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const DetailedQAPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { questions } = useSelector((state) => state.questions);
  const { company, loadingCompany, errorCompany } = useSelector((state) => state.companies);
  const question = questions.find((q) => q.id === id);
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  const [sponsorName, setSponsorName] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null); 

  useEffect(() => {
    // if (question) {
    //   const name = formatForUrl(Sponsors[question.category]);
    //   if (name) {
    //     setSponsorName(name);
    //     dispatch(fetchCompanyByName(name));
    //   }
    // }

    let name;
    // 特殊处理 question.id 在 86 到 97 的范围
    if (question.id >= 86 && question.id <= 97) {
      name = formatForUrl(Sponsors[language][131]); // 使用 ID 为 131 的 sponsor
    } else {
      name = formatForUrl(Sponsors[language][question.category]); // 正常逻辑
    }
    console.log(name)
  
    if (name) {
      setSponsorName(name);
      dispatch(fetchCompanyByName(name));
    }
  }, [question, dispatch]);

  if (!question) {
    return <LoadingSpinner />;
  }

  return (
    <div className="bg-gray-white min-h-screen">
      <header className="bg-yellow p-4 flex items-center">
        <Link
          to={getLocalizedPath(`/QA`, language)}
          className="text-white hover:text-gray-200 transition duration-200 flex items-center"
          aria-label="Go back"
        >
          <FaArrowLeft size={24} />
          <span className="ml-2 text-lg font-semibold">Q&A</span>
        </Link>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className='flex flex-col md:flex-row justify-between'>
            <div>
              <span className="inline-block bg-yellow text-white px-3 py-1 rounded-full text-sm font-semibold mb-4">
                {/* {question.category} */}
                {/* {mapCategory(question.category, language)} */}
                {language === "en" 
                  ? categoryMapping[question.category]
                  : question.category}
              </span>

              <h2 className="text-2xl font-bold text-gray-800 mb-4">{language==="en"?question.title_en:question.title}</h2>
            </div>

            <div className='flex justify-center md:justify-end'>
              {/* 赞助商信息 */}
              {sponsorName && (
                <div className="max-w-64">
                  {loadingCompany ? (
                    <p>Loading sponsor...</p>
                  ) : errorCompany ? (
                    <p>Error loading sponsor.</p>
                  ) : (
                    company && 
                    <>
                    <Item 
                      company={company} 
                      onHover={setHoveredItemId}
                      isHovered={hoveredItemId === company.id}
                    />
                    <p className='mt-2 text-center font-medium text-gray-600'>
                    {language === "en" 
                      ? `Sponsored by ${company.name}`
                      : `本条QA由${company.name}赞助提供`}
                    </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">{language==="en"? "Solution": "回答"}</h3>
            <div className="text-gray-700">
              <FormattedText text={language==="en"? (question.answer_en || "Oops, something went wrong"):(question.answer || '暂无回答')} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DetailedQAPage;

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'; 
import { useSelector } from 'react-redux'; 

const getLocalizedPath = (path, language) => {
  return language === 'cn' ? `/cn${path}` : path;
};

const ContactOption = ({ icon, title }) => {
  const [hovered, setHovered] = useState(false);
  const containerRef = useRef(null);
  return (
    <div className="flex flex-col items-center mx-auto cursor-pointer p-2">
      <div
        ref={containerRef}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`bg-gray-100 flex items-center justify-center mb-2 transition-transform transition-opacity duration-1000 ease-out ${
        hovered ? 'opacity-100 scale-125' : 'opacity-100 scale-100'
      }`}
      >
        <img src={icon} alt={title} className="w-12 h-12 md:w-16 md:h-16 object-contain" />
      </div>
      <h3 className="text-gray-700 text-xs md:text-sm mb-2 text-center">{title}</h3>
    </div>
  );
};

const Connector = () => (
  <div className="flex-grow h-px bg-black -mt-9 lg:block hidden"></div>
);

const GuideIconsItem = ({ steps }) => {
  const language = useSelector((state) => state.language.language); // 使用 useSelector 获取当前语言

  return (
    <div className="py-4 mx-3 md:mx-8">
      <div className="grid grid-cols-4 -mb-8 lg:flex lg:items-center lg:justify-between">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Link to={getLocalizedPath(`/QA`, language)}> {/* 用 Link 包裹 ContactOption */}
              <ContactOption
                icon={step.icon}
                title={step.title}
              />
            </Link>
            {index < steps.length - 1 && <Connector />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GuideIconsItem;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';
import { LanguageData } from '../Common/LanguageData';

const ContactPage = () => {
  const language = useSelector((state) => state.language.language);
  const aboutUsPageData = LanguageData[language].aboutUsPage;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = aboutUsPageData.form.nameRequired;
    if (!formData.email.trim()) tempErrors.email = aboutUsPageData.form.emailRequired;
    if (!formData.message.trim()) tempErrors.message = aboutUsPageData.form.messageRequired;
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setSubmitStatus('success');
          setFormData({ name: '', email: '', phone: '', message: '' });
        } else {
          setSubmitStatus('error');
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmitStatus('error');
      }
    }
  };

  return (
    <div className='bg-white'>
      <div className="max-w-7xl mx-auto px-12 sm:px-16 lg:px-20 pt-12 pb-12">
        {/* Contact Header */}
        <div className="text-center mb-8">
          <p className="text-4xl font-bold text-yellow">
            {aboutUsPageData.form.contact} <span className="text-gray-700">{aboutUsPageData.form.us}</span>
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Business Cooperation Section */}
          <div className="md:w-full">
            <h3 className="text-xl font-semibold mb-4">{aboutUsPageData.form.businessCooperation}</h3>
            <p className="text-gray-600 mb-4">
              {aboutUsPageData.form.cooperationContent}
            </p>
          </div>

          {/* Contact Form Section */}
          <div className="md:w-full">
            <h3 className="text-xl font-semibold mb-4">{aboutUsPageData.form.sendMessage}</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-yellow-500">
                  <FaUser className="text-gray-600 ml-3" />
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={aboutUsPageData.form.name}
                    className="w-full p-2 focus:outline-none rounded-lg"
                  />
                </div>
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
              </div>

              <div>
                <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-yellow-500">
                  <FaEnvelope className="text-gray-600 ml-3" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={aboutUsPageData.form.email}
                    className="w-full p-2 focus:outline-none rounded-lg"
                  />
                </div>
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>

              <div>
                <div className="flex items-center border rounded-lg focus-within:ring-2 focus-within:ring-yellow-500">
                  <FaPhone className="text-gray-600 ml-3" />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder={aboutUsPageData.form.phone}
                    className="w-full p-2 focus:outline-none rounded-lg"
                  />
                </div>
              </div>

              <div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder={aboutUsPageData.form.content}
                  className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 h-32"
                ></textarea>
                {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
              </div>

              <div className="flex flex-col items-center">
                <button
                  type="submit"
                  className="bg-yellow-500 text-white px-8 py-2 rounded-lg hover:bg-yellow-600 transition duration-300 disabled:bg-gray-400"
                  disabled={!formData.name || !formData.email || !formData.message}
                >
                  {aboutUsPageData.form.send}
                </button>
                {submitStatus === 'success' && (
                  <p className="text-yellow-500 text-lg mt-4">{aboutUsPageData.form.success}</p>
                )}
                {submitStatus === 'error' && (
                  <p className="text-yellow-500 text-base mt-4">{aboutUsPageData.form.error}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
  );
};

export default ContactPage;
import React from 'react';
import { Link } from 'react-router-dom';

const EnterpriseRedirect = () => {
    const isEnterpriseUser = false; // 模拟用户是否为企业用户

    return (
        <div className="p-6 bg-white shadow-md rounded-lg">
            {isEnterpriseUser ? (
                <h2 className="text-2xl font-bold mb-4">您已是企业用户</h2>
            ) : (
                <div>
                    <h2 className="text-2xl font-bold mb-4">还不是企业用户？</h2>
                    <p className="mb-4">入驻企信，获取更多企业级服务。</p>
                    <Link to="/enterprise-registration" className="bg-[#f7cc11] text-black px-4 py-2 rounded">
                        入驻企信
                    </Link>
                </div>
            )}
        </div>
    );
};

export default EnterpriseRedirect;

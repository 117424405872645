/**
 * Header includes an image and a title for news and event page.
 * @param {*} 
 * @returns 
 */
export default function ({ image, title, titleStyle = '', opacity='opacity-70'}) {
  return (
    <header
      className="w-full h-80 mb-4 relative">
      <img src={image} className={`w-full h-full object-cover object-center ${opacity}`} />

      <h2 className={`${titleStyle} text-3xl p-3 border-t-2 border-b-2 border-white absolute top-[35%] left-[25%]`}>{title}</h2>

    </header>
  )
}
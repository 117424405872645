// newsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatNewsData } from './newsUtils';

export const fetchLatestNews = createAsyncThunk('news/fetchLatestNews', async () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const response = await fetch(`${baseUrl}/news/latest`);

    let data = await response.json();
    return data;
});

export const fetchNewsById = createAsyncThunk(
    'news/fetchNewsById',
    async (newsId) => {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/news/${newsId}`);

        const data = await response.json();

        // 重构新闻信息
        const formattedNews = formatNewsData(data);

        return formattedNews;
    }
);

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        newsList: [],
        newsItem: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLatestNews.fulfilled, (state, action) => {
                state.newsList = action.payload; // 更新状态
            })
            .addCase(fetchNewsById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNewsById.fulfilled, (state, action) => {
                state.newsItem = action.payload;
                state.loading = false;
            })
            .addCase(fetchNewsById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default newsSlice.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { LanguageData } from '../Common/LanguageData';
import { FaBuilding, FaUsers, FaCity, FaCheckCircle } from 'react-icons/fa';
import AboutUsTop from '../../Assets/About_us_image/About_us_introTop.png';
import AboutUsMid from '../../Assets/About_us_image/About_us_introMid.jpg';
import AboutUsBanner from '../../Assets/About_us_image/About_us_banner.jpg';
import AuMap from '../../Assets/About_us_image/AU_Map.jpg';
import AboutUsIntroLeft from '../../Assets/About_us_image/About_us_introBottomLeft.jpg';
import AboutUsIntroMid from '../../Assets/About_us_image/About_us_introBottomMid.jpg';
import AboutUsIntroRight from '../../Assets/About_us_image/About_us_introBottomRight.jpg';

const CompanyIntroduction = () => {
  const language = useSelector((state) => state.language.language);
  const aboutUsPageData = LanguageData[language]?.aboutUsPage;

  return (
    <div>
      {/* 顶部波浪背景区域 - 保持图片显示 */}
      <div className="relative h-[500px]">
        <div className="absolute inset-0">
          <img
            src={AboutUsBanner}
            alt="building corner"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative max-w-7xl mx-auto h-full flex items-center justify-end px-8 sm:px-12 lg:px-16">
          <div className="text-right">
            <h1 className="text-4xl md:text-5xl font-bold mb-8">
              {aboutUsPageData.aboutUsTitle}
            </h1>
            <div className="flex flex-col items-end space-y-2">
              <p className="text-xl">
                {aboutUsPageData.aboutUsSubtitle}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 主要内容区域 */}
      <div className="max-w-7xl mx-auto px-6 sm:px-12 lg:px-20 pt-12">
        <section className="pt-8 md:pt-16">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
              {/* 左侧内容区域 */}
              <div className="md:col-span-8">
                <h2 className="text-4xl font-bold mb-16 font-medium text-yellow">{aboutUsPageData.aboutUs}</h2>

                <div className="grid grid-cols-12 gap-4 md:gap-8">
                  <div className="col-span-12 md:col-span-3">
                    <div className="flex flex-col gap-2 md:gap-1 mb-6 md:mb-0">
                      <h3 className="text-3xl text-gray-400 font-medium">{aboutUsPageData.company}</h3>
                      <h3 className="text-3xl text-gray-400 font-medium">{aboutUsPageData.intro}</h3>
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-9">
                    <div className="h-full flex flex-col">
                      <div className="mb-8 md:mb-12">
                        <h4 className="text-3xl font-medium mb-6">{aboutUsPageData.section1Title}</h4>
                        <p className="text-gray-600 text-lg mb-6 md:mb-2 font-medium">
                          {aboutUsPageData.section1Subtitle}
                        </p>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 sm:gap-y-2 text-md pb-8">
                        {aboutUsPageData.section1Content.map((content, index) => (
                          <div key={index} className="text-gray-600">
                            {content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 右侧图片区域 */}
              <div className="hidden md:block md:col-span-4 h-full">
                <div className="w-full h-full aspect-[4/5] relative">
                  <img
                    src={AboutUsTop}
                    alt="building corner"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 广泛的合作网络部分 */}
        <section className="pt-8 md:pt-16 bg-gray-200 mb-8 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 mt-12">
              {/* 左侧图片 */}
              <div className="hidden md:block md:col-span-4 relative">
                <div className="absolute bottom-0 left-0 w-full aspect-[4/5]">
                  <img
                    src={AboutUsMid}
                    alt="building corner"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              {/* 右侧内容 */}
              <div className="md:col-span-8 mt-12 md:mt-0 px-4 md:px-12">
                <div className="my-8 md:my-12">
                  <h4 className="text-3xl font-medium mb-6">{aboutUsPageData.section2Title}</h4>
                  <p className="text-gray-600 text-lg mb-8 font-medium">
                    {aboutUsPageData.section2Subtitle}
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-2 text-md mb-16">
                  {aboutUsPageData.section2Content.map((content, index) => (
                    <div key={index} className="text-gray-600">
                      {content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-12">
              <div className="w-full md:w-1/2">
                <h2 className="text-3xl font-medium mb-8">{aboutUsPageData.section3Title}</h2>
                <p className="text-gray-600 text-md border-b">
                  {aboutUsPageData.section3Subtitle}
                </p>
              </div>
              <div className="w-full md:w-2/3 h-[500px] flex items-center justify-center overflow-hidden">
                <img
                  src={AuMap}
                  alt="Australia map"
                  className="w-full object-contain scale-125"
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <section className="py-16 -mt-8">
          <div className="max-w-7xl mx-auto px-6 sm:px-12 lg:px-20 pt-12">
            <div className="mb-16">
              <h2 className="text-3xl font-medium mb-6">{aboutUsPageData.section4Title}</h2>
              <p className="text-gray-600 text-lg">
                {aboutUsPageData.section4Subtitle}
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12 max-w-6xl mx-auto">
              {[
                { title: aboutUsPageData.section4Contentleft, image: AboutUsIntroLeft },
                { title: aboutUsPageData.section4Contentmid, image: AboutUsIntroMid },
                { title: aboutUsPageData.section4Contentright, image: AboutUsIntroRight }
              ].map((card, index) => (
                <div key={index} className="flex justify-center w-full">
                  <div className="w-full max-w-sm bg-white shadow-lg transform transition-transform duration-300 hover:-translate-y-2">
                    <div className="p-6">
                      <div className="h-20"> {/* 固定标题容器高度 */}
                        <h3 className="text-xl font-medium">{card.title}</h3>
                      </div>
                      <div className="aspect-[4/3] w-full overflow-hidden">
                        <img
                          src={card.image}
                          alt="card image"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="absolute right-0 top-0 bottom-0 w-2 bg-gradient-to-l from-gray-300 to-transparent"></div>
                    <div className="absolute bottom-0 left-0 right-0 h-2 bg-gradient-to-t from-gray-300 to-transparent"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CompanyIntroduction;
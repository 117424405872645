import Loan_Step1 from '../../Assets/GuideIcons/Loan-Step1.svg';
import Loan_Step2 from '../../Assets/GuideIcons/Loan-Step2.svg';
import Loan_Step3 from '../../Assets/GuideIcons/Loan-Step3.svg';
import Loan_Step4 from '../../Assets/GuideIcons/Loan-Step4.svg';
import Loan_Step5 from '../../Assets/GuideIcons/Loan-Step5.svg';
import Loan_Step6 from '../../Assets/GuideIcons/Loan-Step6.svg';
import Loan_Step7 from '../../Assets/GuideIcons/Loan-Step7.svg';
import Loan_Step8 from '../../Assets/GuideIcons/Loan-Step8.svg';
import Accounting_Step1 from '../../Assets/GuideIcons/Accounting-Step1.png';
import Accounting_Step2 from '../../Assets/GuideIcons/Accounting-Step2.png';
import Accounting_Step3 from '../../Assets/GuideIcons/Accounting-Step3.png';
import Accounting_Step4 from '../../Assets/GuideIcons/Accounting-Step4.png';
import Accounting_Step5 from '../../Assets/GuideIcons/Accounting-Step5.png';
import Accounting_Step6 from '../../Assets/GuideIcons/Accounting-Step6.png';
import Accounting_Step7 from '../../Assets/GuideIcons/Accounting-Step7.png';
import Accounting_Step8 from '../../Assets/GuideIcons/Accounting-Step8.png';
import Accounting_Step9 from '../../Assets/GuideIcons/Accounting-Step9.png';
import Accounting_Step10 from '../../Assets/GuideIcons/Accounting-Step10.png';
import Agency_Step1 from '../../Assets/GuideIcons/Agency-Step1.png';
import Agency_Step2 from '../../Assets/GuideIcons/Agency-Step2.png';
import Agency_Step3 from '../../Assets/GuideIcons/Agency-Step3.png';
import Agency_Step4 from '../../Assets/GuideIcons/Agency-Step4.png';
import Agency_Step5 from '../../Assets/GuideIcons/Agency-Step5.png';
import Agency_Step6 from '../../Assets/GuideIcons/Agency-Step6.png';
import Agency_Step7 from '../../Assets/GuideIcons/Agency-Step7.png';
import Agency_Step8 from '../../Assets/GuideIcons/Agency-Step8.png';
import Agency_Step9 from '../../Assets/GuideIcons/Agency-Step9.png';
import Agency_Step10 from '../../Assets/GuideIcons/Agency-Step10.png';
import Agency_Step11 from '../../Assets/GuideIcons/Agency-Step11.png';
import Agency_Step12 from '../../Assets/GuideIcons/Agency-Step12.png';
import Agency_Step13 from '../../Assets/GuideIcons/Agency-Step13.png';
import Builder_Step1 from '../../Assets/GuideIcons/Builder-Step1.svg';
import Builder_Step2 from '../../Assets/GuideIcons/Builder-Step2.svg';
import Builder_Step3 from '../../Assets/GuideIcons/Builder-Step3.svg';
import Builder_Step4 from '../../Assets/GuideIcons/Builder-Step4.svg';
import Builder_Step5 from '../../Assets/GuideIcons/Builder-Step5.svg';
import Builder_Step6 from '../../Assets/GuideIcons/Builder-Step6.svg';
import Builder_Step7 from '../../Assets/GuideIcons/Builder-Step7.svg';
import Builder_Step8 from '../../Assets/GuideIcons/Builder-Step8.svg';
import Builder_Step9 from '../../Assets/GuideIcons/Builder-Step9.svg';
import Builder_Step10 from '../../Assets/GuideIcons/Builder-Step10.svg';
import Design_Step1 from '../../Assets/GuideIcons/Design-Step1.png';
import Design_Step2 from '../../Assets/GuideIcons/Design-Step2.png';
import Design_Step3 from '../../Assets/GuideIcons/Design-Step3.png';
import Design_Step4 from '../../Assets/GuideIcons/Design-Step4.png';
import Design_Step5 from '../../Assets/GuideIcons/Design-Step5.png';
import Design_Step6 from '../../Assets/GuideIcons/Design-Step6.png';
import Develop_Step1 from '../../Assets/GuideIcons/Develop-Step1.svg';
import Develop_Step2 from '../../Assets/GuideIcons/Develop-Step2.svg';
import Develop_Step3 from '../../Assets/GuideIcons/Develop-Step3.svg';
import Develop_Step4 from '../../Assets/GuideIcons/Develop-Step4.svg';
import Develop_Step5 from '../../Assets/GuideIcons/Develop-Step5.svg';
import Develop_Step6 from '../../Assets/GuideIcons/Develop-Step6.svg';
import Develop_Step7 from '../../Assets/GuideIcons/Develop-Step7.svg';
import Indoor_Step1 from '../../Assets/GuideIcons/Indoor-Step1.png';
import Indoor_Step2 from '../../Assets/GuideIcons/Indoor-Step2.png';
import Indoor_Step3 from '../../Assets/GuideIcons/Indoor-Step3.png';
import Indoor_Step4 from '../../Assets/GuideIcons/Indoor-Step4.png';
import Indoor_Step5 from '../../Assets/GuideIcons/Indoor-Step5.png';
import Indoor_Step6 from '../../Assets/GuideIcons/Indoor-Step6.png';
import Indoor_Step7 from '../../Assets/GuideIcons/Indoor-Step7.png';
import Law_Step1 from '../../Assets/GuideIcons/Law-Step1.png';
import Law_Step2 from '../../Assets/GuideIcons/Law-Step2.png';
import Law_Step3 from '../../Assets/GuideIcons/Law-Step3.png';
import Law_Step4 from '../../Assets/GuideIcons/Law-Step4.png';
import Law_Step5 from '../../Assets/GuideIcons/Law-Step5.png';
import Material_Step1 from '../../Assets/GuideIcons/Material-Step1.png';
import Material_Step2 from '../../Assets/GuideIcons/Material-Step2.png';
import Material_Step3 from '../../Assets/GuideIcons/Material-Step3.png';
import Material_Step4 from '../../Assets/GuideIcons/Material-Step4.png';
import Material_Step5 from '../../Assets/GuideIcons/Material-Step5.png';
import Material_Step6 from '../../Assets/GuideIcons/Material-Step6.png';
import Material_Step7 from '../../Assets/GuideIcons/Material-Step7.png';
import Material_Step8 from '../../Assets/GuideIcons/Material-Step8.png';
import Material_Step9 from '../../Assets/GuideIcons/Material-Step9.png';

// Purpose: To store the language data.
export const LanguageData = {
    en: {
        header: {
            slogan: 'QX YellowPage',
            home: 'Home',
            explore: 'Company Explore',
            calendar: 'Event',
            about: 'About Us',
            all: 'All',
            account: 'My Account',
            logout: 'Logout',
        }, 
        landing:{
            company1: "Wen Mortgage, bringing dream homes within reach",
            company2: "Livecomfy, a Leading Real Estate Company in Canberra",
            companiesTitle: "Featured Companies",
            newsTitle: "NEWS CENTER",
            eventsTitle: "UPCOMING EVENTS",
            more: "View More →",
            loading: "Loading...",
            emptyNews: "No news to display",
            expired: "Expired",
        },
        companiesPage: {
            chooseIndustry: 'Select Industry',
            // regions: {
            //     All: 'All',
            //     ACT: 'ACT',
            //     NSW: 'NSW',
            //     VIC: 'VIC',
            //     QLD: 'QLD',
            //     Other:'WA SA NT TAS'
            // }
        },
        industries: [
            {name: 'All', path: '/companies'},
            {name: 'Property Development', database: "Property Development", path: '/companies/realestate'},
            {name: 'Construction Design', database: "Construction Design", path: '/companies/design'},
            {name: 'Housing Construction', database: "Housing Construction", path: '/companies/builder'},
            {name: 'Building Materials', database: "material", path: '/companies/material'},
            {name: 'Property Decoration', database: "decoration", path: '/companies/decoration'},
            {name: 'Real Estate Agent', database: "agency", path: '/companies/agency'},
            {name: 'Mortgage Financing', database: "mortgage", path: '/companies/mortgage'},
            {name: 'Legal Services', database: "law", path: '/companies/law'},
            {name: 'Accounting Services', database: "accounting", path: '/companies/accounting' }
        ],
        SingleCompanyPage: {
            location: 'Location: ',
            business: 'Main Business: ',
            companyIntro: 'Company Introduction',
            products: 'Products Showcase',
            productsContent: 'Here we showcase our main products and services, including the latest technological solutions and innovative products. We are committed to providing cutting-edge technology to meet the needs of different customers. Our products cover various fields from software development to hardware solutions.',
            comment: 'Comment Section',
            commentPlaceholder: 'Enter your comment...',
            submitComment: 'Submit Comment',
            relevantCompanies: 'Relevant Companies',
            noRelevantCompanies: 'No relevant companies found',
            screenshot: 'Take Screenshot',
        },
        newsPage: {
            newsTitle: 'NEWS CENTER',
            viewDetails: 'View Details',
        },
        eventsPage: {
            eventsTitle: 'Event Details',
            viewDetails: 'Details →',
            joinEvent: 'Join Event',
            countDown: 'Event Countdown',
            date: 'Date',
            time: 'Time',
            fee: 'Fee',
            host: 'Host',
            location: 'Location',    
            eventExpired: 'Event Expired',         
        },
        aboutUsPage: {
            aboutUs: 'About Qixin',
            company:'Company',
            intro:'Intro',
            aboutUsTitle: 'One Of The Most Comprehensive Real Estate and Construction Industry Information Website in Australia',
            aboutUsSubtitle: 'Providing comprehensive industry information and services, connecting you with limitless opportunities in the Australian construction and real estate sector',
            section1Title: 'Complete Industry Categories',
            section1Subtitle: "Covering 9 major sectors related to construction and real estate, providing you with comprehensive market insights",
            section1Content:['Property Development','Construction Design','Housing Construction','Building Materials','Real Estate Agent','Property Decoration','Mortgage Financing','Legal Consulting','Accounting Services'],
            section2Title: 'Extensive Cooperation Network',
            section2Subtitle: "Established partnerships with over 700 companies, building a strong business service bridge for you",
            section2Content: [
                'Top Real Estate Development Companies', 
                'Renowned Housing Design Firms',
                'Large-scale Home Building Enterprises',
                'High-quality Building Material Suppliers',
                'Experienced Real Estate Agents', 
                'Professional Design and Renovation',
                'Home Loan Service Providers',
                'Professional Legal Consulting Firms',
                'Well-known Accounting Firms'
            ],
            section3Title: 'Localized Services',
            section3Subtitle: 'Covering major cities in Australia, providing targeted local market information and support.',
            section3Content: [
                'Canberra',
                'Sydney',
                'Melbourne',
                'Brisbane',
                'Perth',
                'Adelaide',
                'Gold Coast',
                'Wollongong',
            ], 
            section4Title: 'One-stop Solutions',
            section4Subtitle: 'From market analysis to business matchmaking, we provide comprehensive professional services.',
            section4Contentleft: 'Market Trend Analysis',
            section4Contentmid: 'Business Partner Matching',
            section4Contentright:'Legal and Regulatory Consulting',
            form: {
                contact: 'Contact ',
                us: 'Us',
                businessCooperation: 'Business Cooperation',
                cooperationContent: 'Company information entry, updates, industry upstream and downstream consulting, job position consultation, event cooperation, and any other areas for consultation or cooperation, please feel free to contact us at any time.',
                sendMessage: 'Send Message',
                name: 'Name *',
                email: 'Email *',
                phone: 'Phone',
                content: 'Content *',
                send: 'Send',
                success: 'Message Sent!',
                error: 'Failed, please try again',
            }
        },
        footer: {
            contact:'Contact Details',
            categories: 'Business Categories',
            team: 'Team',
            about: 'About Qixin',
            service: 'Customer Service',
            advertise: 'Livecomfy, a Leading Real Estate Company in Canberra',
            register: 'View Details',
        }, 
        stepsMap:{
            mortgage: [
              { icon: Loan_Step1, title: "1.Loan Documents" },
              { icon: Loan_Step2, title: "2.Pre-Approval" },
              { icon: Loan_Step3, title: "3.Pre-Approval Approved" },
              { icon: Loan_Step4, title: "4.House Viewing & Selection" },
              { icon: Loan_Step5, title: "5.Holding Deposit" },
              { icon: Loan_Step6, title: "6.Contract Exchange" },
              { icon: Loan_Step7, title: "7.Contract Deposit" },
              { icon: Loan_Step8, title: "8.Property Settlement" }
            ],
            accounting: [
              { icon: Accounting_Step1, title: "1.Loan Documents" },
              { icon: Accounting_Step2, title: "2.Pre-Approval" },
              { icon: Accounting_Step3, title: "3.Pre-Approval Approved" },
              { icon: Accounting_Step4, title: "4.House Viewing & Selection" },
              { icon: Accounting_Step5, title: "5.Holding Deposit" },
              { icon: Accounting_Step6, title: "6.Contract Exchange" },
              { icon: Accounting_Step7, title: "7.Contract Deposit" },
              { icon: Accounting_Step8, title: "8.Property Settlement" },
              { icon: Accounting_Step9, title: "9.Contract Deposit" },
              { icon: Accounting_Step10, title: "10.Property Settlement" }
            ],
            agency: [
              { icon: Agency_Step1, title: "1.Select Property" },
              { icon: Agency_Step2, title: "2.Sales Contract" },
              { icon: Agency_Step3, title: "3.Select Loans" },
              { icon: Agency_Step4, title: "4.Confirm Ownership" },
              { icon: Agency_Step5, title: "5.Arrange Inspections" },
              { icon: Agency_Step6, title: "6.Make an Offer" },
              { icon: Agency_Step7, title: "7.Contract Exchange" },
              { icon: Agency_Step8, title: "8.Pay Deposit" },
              { icon: Agency_Step9, title: "9.Prepare Documents" },
              { icon: Agency_Step10, title: "10.Pay StampDuty" },
              { icon: Agency_Step11, title: "11.Arrange Insurance" },
              { icon: Agency_Step12, title: "12.Final Inspection" },
              { icon: Agency_Step13, title: "13.Settlement Day" }
            ],
            builder: [
              { icon: Builder_Step1, title: "1. Decide to Build" },
              { icon: Builder_Step2, title: "2. Purchase Land" },
              { icon: Builder_Step3, title: "3. Builder Selection" },
              { icon: Builder_Step4, title: "4. Clear Site" },
              { icon: Builder_Step5, title: "5. Lay Foundation" },
              { icon: Builder_Step6, title: "6. Build Framework" },
              { icon: Builder_Step7, title: "7. Lock-up Stage" },
              { icon: Builder_Step8, title: "8. Internal Fit-out" },
              { icon: Builder_Step9, title: "9. Final Construction" },
              { icon: Builder_Step10, title: "10. Acceptance & Handover" }
            ],
            design: [
              { icon: Design_Step1, title: "1. Preliminary Design" },
              { icon: Design_Step2, title: "2. Concept Design" },
              { icon: Design_Step3, title: "3. Detailed Design" },
              { icon: Design_Step4, title: "4. Construction Documents" },
              { icon: Design_Step5, title: "5. Building Permits" },
              { icon: Design_Step6, title: "6. Construction Management" }
            ],
            realestate: [
              { icon: Develop_Step1, title: "1. Preliminary Preparation" },
              { icon: Develop_Step2, title: "2. Land Attributes" },
              { icon: Develop_Step3, title: "3. Development Application" },
              { icon: Develop_Step4, title: "4. Due Diligence" },
              { icon: Develop_Step5, title: "5. Bank Loan" },
              { icon: Develop_Step6, title: "6. Select Builder" },
              { icon: Develop_Step7, title: "7. Property Handover" }
            ],
            decoration: [
              { icon: Indoor_Step1, title: "1. Define Requirements" },
              { icon: Indoor_Step2, title: "2. In-Depth Research" },
              { icon: Indoor_Step3, title: "3. Brainstorming" },
              { icon: Indoor_Step4, title: "4. Architectural Design" },
              { icon: Indoor_Step5, title: "5. Concept Design" },
              { icon: Indoor_Step6, title: "6. Construction Drawings" },
              { icon: Indoor_Step7, title: "7. Start Construction" }
            ],
            law: [
              { icon: Law_Step1, title: "1. Review Contract" },
              { icon: Law_Step2, title: "2. Communicate with Lawyer" },
              { icon: Law_Step3, title: "3. Sign Contract" },
              { icon: Law_Step4, title: "4. Full Loan Approval" },
              { icon: Law_Step5, title: "5. Property Settlement" }
            ],
            material: [
              { icon: Material_Step1, title: "1. Foundation Formwork" },
              { icon: Material_Step2, title: "2. Timber & Steel" },
              { icon: Material_Step3, title: "3. Waterproofing" },
              { icon: Material_Step4, title: "4. Electrical Wiring" },
              { icon: Material_Step5, title: "5. Insulation Materials" },
              { icon: Material_Step6, title: "6. Gypsum Board" },
              { icon: Material_Step7, title: "7. Exterior Wall Paint" },
              { icon: Material_Step8, title: "8. Flooring & Tiles" },
              { icon: Material_Step9, title: "9. Bathroom Fittings" }
            ]
        }, 
        QAPage : {
          search: "Search",
          searchPlaceholder: "Search for questions...",
          clear: "Clear",
          more: "View More ...",
        }          
    },
    cn: {
        header:{
            slogan: '企信黄页',
            home: '首页',
            explore: '公司探索',
            calendar: '活动日历',
            about: '关于企信',
            all: '全部',
            account: '账户信息',
            logout: '退出登录',
        },
        landing:{
            company1: "Wen Mortgage，让梦想住房触手可及",
            company2: "Livecomfy，堪培拉地产龙头企业",
            companiesTitle: "官方合作伙伴",
            newsTitle: "新闻中心",
            eventsTitle: "近期活动",
            more: "查看更多 →",
            loading: "加载中...",
            emptyNews: "没有新闻可显示",
            expired: "已结束",  
        },
        industries: [
            {name: '全部', path: '/companies'},
            {name: '地产开发', database: "地产开发", path: '/companies/realestate'},
            {name: '建筑设计', database: "建筑设计", path: '/companies/design'},
            {name: '房屋建造', database: "房屋建造", path: '/companies/builder'},
            {name: '建筑材料', database: "建筑材料", path: '/companies/material'},
            {name: '室内装修', database: "室内装修", path: '/companies/decoration'},
            {name: '房产经纪', database: "房产经纪", path: '/companies/agency'},
            {name: '房屋信贷', database: "房屋信贷", path: '/companies/mortgage'},
            {name: '法律咨询', database: "法律咨询", path: '/companies/law' }, 
            {name: '会计事务', database: "会计事务", path: '/companies/accounting' },
        ], 
        SingleCompanyPage: {
            location: '所在地区: ',
            business: '主营业务: ',
            companyIntro: '公司介绍',
            products: '产品展示',
            productsContent: '这里展示了我们的主要产品和服务，包括最新的技术解决方案和创新产品。我们致力于提供最前沿的科技，满足不同客户的需求。我们的产品涵盖了从软件开发到硬件解决方案的各个领域。',
            comment: '评论区',
            commentPlaceholder: '输入您的评论...',
            submitComment: '发表评论',
            relevantCompanies: '相关公司',
            noRelevantCompanies: '未找到相关公司',
            screenshot: '一键截图',
        },
        newsPage: {
            newsTitle: '新闻中心',
            viewDetails: '查看详情',
        },
        eventsPage: {
            eventsTitle: '活动详情',
            viewDetails: '详情 →',
            joinEvent: '参加活动',
            countDown: '活动倒计时',  
            date: '日期',
            time: '时间',
            fee: '费用',
            host: '主办方',
            location: '地点',
            eventExpired: '活动已结束',
        },
        aboutUsPage: {
            aboutUs: '关于企信',
            company:'公司',
            intro:'简介',
            aboutUsTitle: '澳洲最详细的房产建筑行业信息网站',
            aboutUsSubtitle: '提供全面的行业信息和服务，连接您与澳洲建筑地产行业的无限机遇',
            section1Title: '齐全的行业分类',
            section1Subtitle: '涵盖9大类建筑地产相关行业，为您提供全方位的市场洞察',
            section1Content:['地产开发','房屋设计','房屋建造','建筑材料','房产经纪','房屋装修','房屋信贷','法律咨询','会计事务'],
            section2Title: '广泛的合作网络',
            section2Subtitle: '与700+家企业建立合作关系，为您搭建强大的商业服务桥梁',
            section2Content: [
                '顶级地产开发公司', 
                '知名房屋设计事务所',
                '大型房屋建造企业',
                '高品质建筑材料供应商',
                '资深房产经纪人', 
                "专业室内设计和装修公司",
                "房屋信贷服务机构",
                "专业法律咨询公司",
                "知名会计事务所"
            ],
            section3Title: '本地化服务',
            section3Subtitle: '覆盖澳洲主要城市，提供针对性的本地市场信息和支持。',
            section3Content: [
                '堪培拉',
                '悉尼',
                '墨尔本',
                '布里斯班',
                '珀斯',
                "阿德莱德",
                "黄金海岸",
                "卧龙岗",
            ],  
            section4Title: '一站式解决方案',
            section4Subtitle: '从市场分析到商务对接，我们为您提供全方位的专业服务。',
            section4Contentleft: '市场趋势分析',
            section4Contentmid: '商业伙伴匹配',
            section4Contentright:'法律法规咨询',
            form: {
                contact: '联系 ',
                us: '我们',
                businessCooperation: '商务合作',
                cooperationContent: '企业信息录入，更新，行业上下游咨询，相关工作职位咨询，活动合作举办，有任何需要咨询合作的地方，敬请随时联系。',
                sendMessage: '发送消息',
                name: '姓名 *',
                email: '邮箱 *',
                phone: '电话',
                content: '内容 *',
                send: '发送',
                success: '发送成功!',
                error: '发送失败, 请重试',
            }
        },

        footer: {
            contact:'联系方式',
            categories: '业务分类',
            team: '团队',
            about: '关于企信',
            service: '客服',
            advertise: 'Livecomfy, 堪培拉地产龙头企业',
            register: '查看更多',
        }, 
        stepsMap: {
            mortgage: [
              { icon: Loan_Step1, title: "1.贷款材料"},
              { icon: Loan_Step2, title: "2.办理预批"},
              { icon: Loan_Step3, title: "3.预批通过"},
              { icon: Loan_Step4, title: "4.看房选房"},
              { icon: Loan_Step5, title: "5.交付小定"},
              { icon: Loan_Step6, title: "6.交换合同"},
              { icon: Loan_Step7, title: "7.交付大定"},
              { icon: Loan_Step8, title: "8.房产交割"}
            ],
            accounting: [
              { icon: Accounting_Step1, title: "1.贷款材料"},
              { icon: Accounting_Step2, title: "2.办理预批"},
              { icon: Accounting_Step3, title: "3.预批通过"},
              { icon: Accounting_Step4, title: "4.看房选房"},
              { icon: Accounting_Step5, title: "5.交付小定"},
              { icon: Accounting_Step6, title: "6.交换合同"},
              { icon: Accounting_Step7, title: "7.交付大定"},
              { icon: Accounting_Step8, title: "8.房产交割"},
              { icon: Accounting_Step9, title: "9.交付大定"},
              { icon: Accounting_Step10, title: "10.房产交割"}
            ],
            agency: [
              { icon: Agency_Step1, title: "1.寻找房产"},
              { icon: Agency_Step2, title: "2.销售合同"},
              { icon: Agency_Step3, title: "3.寻找贷款"},
              { icon: Agency_Step4, title: "4.确定产权"},
              { icon: Agency_Step5, title: "5.安排检查"},
              { icon: Agency_Step6, title: "6.购买房产"},
              { icon: Agency_Step7, title: "7.交换合同"},
              { icon: Agency_Step8, title: "8.支付定金"},
              { icon: Agency_Step9, title: "9.准备文件"},
              { icon: Agency_Step10, title: "10.付印花税"},
              { icon: Agency_Step11, title: "11.安排保险"},
              { icon: Agency_Step12, title: "12.最终检查"},
              { icon: Agency_Step13, title: "13.交割日"}
            ],
            builder: [
              { icon: Builder_Step1, title: "1.决定建房"},
              { icon: Builder_Step2, title: "2.购买土地"},
              { icon: Builder_Step3, title: "3.选施工队"},
              { icon: Builder_Step4, title: "4.清理现场"},
              { icon: Builder_Step5, title: "5.铺设地基"},
              { icon: Builder_Step6, title: "6.框架搭建"},
              { icon: Builder_Step7, title: "7.封闭外壳"},
              { icon: Builder_Step8, title: "8.内部装修"},
              { icon: Builder_Step9, title: "9.最终竣工"},
              { icon: Builder_Step10, title: "10.验收交付"},
            ],
            design: [
              { icon: Design_Step1, title: "1.前期设计"},
              { icon: Design_Step2, title: "2.方案设计"},
              { icon: Design_Step3, title: "3.深化设计"},
              { icon: Design_Step4, title: "4.施工文件"},
              { icon: Design_Step5, title: "5.建筑许可"},
              { icon: Design_Step6, title: "6.施工管理"},
            ],
            realestate: [
              { icon: Develop_Step1, title: "1.前期准备" },
              { icon: Develop_Step2, title: "2.土地属性" },
              { icon: Develop_Step3, title: "3.开发申请" },
              { icon: Develop_Step4, title: "4.尽职调查" },
              { icon: Develop_Step5, title: "5.银行贷款" },
              { icon: Develop_Step6, title: "6.选建筑商" },
              { icon: Develop_Step7, title: "7.房屋交付" }
            ],
            decoration: [
              { icon: Indoor_Step1, title: "1.确定需求" },
              { icon: Indoor_Step2, title: "2.深入调研" },
              { icon: Indoor_Step3, title: "3.头脑风暴" },
              { icon: Indoor_Step4, title: "4.建筑设计" },
              { icon: Indoor_Step5, title: "5.概念设计" },
              { icon: Indoor_Step6, title: "6.施工图" },
              { icon: Indoor_Step7, title: "7.开始施工" }
            ],
            law: [
              { icon: Law_Step1, title: "1.查看合同" },
              { icon: Law_Step2, title: "2.律师沟通" },
              { icon: Law_Step3, title: "3.签订合同" },
              { icon: Law_Step4, title: "4.贷款全批" },
              { icon: Law_Step5, title: "5.房屋交割" },
            ],
            material: [
              { icon: Material_Step1, title: "1.地基模板" },
              { icon: Material_Step2, title: "2.木材钢材" },
              { icon: Material_Step3, title: "3.防水层" },
              { icon: Material_Step4, title: "4.电气管道" },
              { icon: Material_Step5, title: "5.隔热材料" },
              { icon: Material_Step6, title: "6.石膏板" },
              { icon: Material_Step7, title: "7.外墙涂料" },
              { icon: Material_Step8, title: "8.地板瓷砖" },
              { icon: Material_Step9, title: "9.卫浴设备" },
            ],
          },
    }, 
    QAPage : {
      search: "搜索",
      searchPlaceholder: "搜索问题...",
      clear: "清除",
      more: "加载更多...",
    }
  };
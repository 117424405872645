import React from 'react';
import { FaCaretDown } from 'react-icons/fa';

const BreadcrumbHeader = ({ isMobile, handleMobileMenuToggle, activeTab }) => {
    const handleMobileMenu = () => {
        handleMobileMenuToggle(true);
        console.log(true);
    };

    return (
        <div className="p-4 bg-white shadow-md flex flex-row">
            <div className='flex-1'>
                <nav className="text-gray-500">
                    <ol className="list-reset flex font-sans">
                        <li><a href="#" className="text-blue-500 font-sans">用户管理系统</a></li>
                        <li><span className="mx-2">/</span></li>
                        <li>
                            {activeTab === 'account' ? '账户信息' :
                                activeTab === 'messages' ? '消息列表' :
                                    activeTab === 'history' ? '浏览记录' :
                                        activeTab === 'points' ? '积分商城' :
                                            activeTab === 'enterpriseRedirect' ? '企业用户入驻' :
                                                '无效选项'}
                        </li>
                    </ol>
                </nav>
            </div>
            {isMobile && (
                <div className='flex flex-row items-center text-blue-500 font-sans'>
                    <div className='text-gray-500 font-sans'>功能切换</div>
                    <div className="ml-2">
                        <FaCaretDown
                            className="cursor-pointer"
                            onClick={handleMobileMenu}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BreadcrumbHeader;
